/* eslint-disable no-console */

'use client';

import { getCookie, setCookie } from 'cookies-next';
import type { User as FirebaseUser } from 'firebase/auth';
import {
  applyActionCode as FirebaseApplyActionCode,
  EmailAuthProvider,
  onIdTokenChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signOut,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import { useSearchParams } from 'next/navigation';
import { filterStandardClaims } from 'next-firebase-auth-edge/lib/auth/claims';
import * as React from 'react';

import { Endpoints } from '@/shared/config/endpoint.config';
import { HOME } from '@/shared/config/route-links.config';
import { useFirebaseAuth } from '@/shared/firebase/config/firebase.config';
import useFCM from '@/shared/firebase/fmc/hooks/useFCM';
import type { User } from '@/stores/authContext';
import { AuthContext } from '@/stores/authContext';

import type { SignupWithEmailPasswordParams } from './types';

export interface AuthProviderProps {
  serverUser: User | null;
  children: React.ReactNode;
}
const CHECK_FMC_EXIST_KEY = 'fmcTokenExist';
export const setIsFMCTokenExist = (exist: boolean) => {
  setCookie(CHECK_FMC_EXIST_KEY, exist);
};

export const isFMCTokenExist = () => {
  return Boolean(getCookie(CHECK_FMC_EXIST_KEY));
};

export const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
  serverUser,
  children,
}) => {
  const { getFirebaseAuth } = useFirebaseAuth();
  const [user, setUser] = React.useState(serverUser);
  const [error, setError] = React.useState('');
  const [withPasswordLoading, setWithPasswordLoading] = React.useState(false);
  const [withGoogleLoading, setWithGoogleLoading] = React.useState(false);
  const [withSSOLoading, setWithSSOLoading] = React.useState(false);
  const [userAuthLoading, setUserAuthLoading] = React.useState(false);
  const [passwordChangeLoading, setPasswordChangeLoading] =
    React.useState(false);
  const [emailChangeLoading, setEmailChangeLoading] = React.useState(false);
  const [applyActionCodeLoading, setApplyActionCodeLoading] =
    React.useState(false);
  const [applyActionCodeSuccess, setApplyActionCodeSuccess] =
    React.useState<boolean>();
  const [sendPasswordResetEmailSuccess, setSendPasswordResetEmailSuccess] =
    React.useState<boolean>();

  const firstLoadRef = React.useRef(true);

  const params = useSearchParams();

  const { addFMCToken, removeFMCToken } = useFCM();

  /**
   * Logout
   */
  const handleLogout = async () => {
    setIsFMCTokenExist(false);
    await removeFMCToken();
    await signOut(getFirebaseAuth);
    await fetch('/api/logout', {
      method: 'GET',
    });
    window.location.reload();
  };

  /**
   * email check verification with oobCode
   */
  const applyActionCode = async (oobCode: string): Promise<void> => {
    setApplyActionCodeLoading(true);

    try {
      await FirebaseApplyActionCode(getFirebaseAuth, oobCode);
      await fetch('/api/custom-claims', {
        method: 'POST',
      });
      setError('');
      setApplyActionCodeLoading(false);
      setApplyActionCodeSuccess(true);
    } catch (errorObject) {
      setApplyActionCodeLoading(false);
      setApplyActionCodeSuccess(false);
      setError('Invalid oobCode.');
    }
  };

  /**
   * reauthenticate with credential
   */
  const reauthenticateUser = async (password: string): Promise<boolean> => {
    setUserAuthLoading(true);
    const { currentUser } = getFirebaseAuth;
    let userCredential;

    try {
      if (currentUser && password) {
        const credential = EmailAuthProvider.credential(
          currentUser.email!,
          password
        );
        userCredential = await reauthenticateWithCredential(
          currentUser,
          credential
        );
      }
      setUserAuthLoading(false);
    } catch (errorObject) {
      setUserAuthLoading(false);

      console.log(errorObject);
    }

    return !!userCredential || false;
  };

  /**
   * reauthenticate with credential
   */
  const resetPassword = async (email: string): Promise<void> => {
    setUserAuthLoading(true);
    if (!email) {
      setError('Email is required');
      setUserAuthLoading(false);
      return;
    }
    try {
      if (getFirebaseAuth && email) {
        await sendPasswordResetEmail(getFirebaseAuth, email);
        setUserAuthLoading(false);
        setSendPasswordResetEmailSuccess(true);
        setError('');
      }
    } catch (errorObject: any) {
      if (errorObject.code === 'auth/user-not-found') {
        setError('User not found.');
        setUserAuthLoading(false);
        setSendPasswordResetEmailSuccess(false);
      }
    }
  };

  /**
   * update password
   */

  const updateUserPassword = async (password: string): Promise<void> => {
    setPasswordChangeLoading(true);
    const { currentUser } = getFirebaseAuth;
    try {
      if (currentUser) await updatePassword(currentUser, password);
      setPasswordChangeLoading(false);
    } catch (errorObject) {
      setPasswordChangeLoading(false);
      console.log(errorObject);
    }
  };

  /**
   * update email address
   */

  const updateUserEmail = async (email: string): Promise<void> => {
    setEmailChangeLoading(true);
    const { currentUser } = getFirebaseAuth;
    try {
      if (currentUser) await updateEmail(currentUser, email);
      setEmailChangeLoading(false);
    } catch (errorObject) {
      setEmailChangeLoading(false);
      console.log(errorObject);
    }
  };

  /**
   * Login with email and password
   */
  const loginWithEmailAndPassword = async (email: string, password: string) => {
    const { signInWithEmailAndPassword } = await import('firebase/auth');
    try {
      setWithPasswordLoading(true);
      await signInWithEmailAndPassword(getFirebaseAuth, email, password);
      setError('');
      setWithPasswordLoading(false);
    } catch (errorObject: any) {
      if (
        errorObject.code === 'auth/wrong-password' ||
        errorObject.code === 'auth/user-not-found'
      ) {
        setError('Invalid email / password combination.');
      }
      setWithPasswordLoading(false);
    }
  };

  /**
   * Signup with email and password
   * @param email - email address
   * @param password - password
   */
  const signUpWithEmailAndPassword = async ({
    email,
    password,
  }: SignupWithEmailPasswordParams) => {
    const { createUserWithEmailAndPassword } = await import('firebase/auth');
    try {
      setWithPasswordLoading(true);
      setError('');
      await createUserWithEmailAndPassword(getFirebaseAuth, email, password);
      await loginWithEmailAndPassword(email, password);

      setWithPasswordLoading(false);
    } catch (errorObject: any) {
      if (errorObject.code === 'auth/email-already-in-use') {
        setError('The email already in use.');
      }
      if (errorObject.code === 'auth/network-request-failed') {
        setError('Network request is failed. Please check your connection.');
      }
      setWithPasswordLoading(false);
    }
  };

  /**
   * Login with SSO
   */

  const loginWithSSO = async (providerId: string) => {
    setWithSSOLoading(true);
    setError('');
    try {
      const {
        SAMLAuthProvider,
        signInWithPopup,
        browserPopupRedirectResolver,
      } = await import('firebase/auth');
      const provider = new SAMLAuthProvider(providerId);
      await signInWithPopup(
        getFirebaseAuth,
        provider,
        browserPopupRedirectResolver
      );
    } catch (errorObject: any) {
      console.log(errorObject);

      if (errorObject.code === 'auth/popup-closed-by-user') {
        setError('Auth popup is closed.');
      }
    } finally {
      setWithSSOLoading(false);
    }
  };

  /**
   * Login with google provider
   */
  const loginWithGoogle = async () => {
    setWithGoogleLoading(true);
    setError('');
    try {
      const {
        signInWithPopup,
        browserPopupRedirectResolver,
        GoogleAuthProvider,
      } = await import('firebase/auth');
      const provider = new GoogleAuthProvider();
      await signInWithPopup(
        getFirebaseAuth,
        provider,
        browserPopupRedirectResolver
      );
    } catch (errorObject: any) {
      if (errorObject.code === 'auth/popup-closed-by-user') {
        setError('Auth popup is closed.');
      }
    } finally {
      setWithGoogleLoading(false);
    }
  };

  /**
   * Listen to token change
   * @param firebaseUser
   * @returns
   */

  const handleIdTokenChanged = async (firebaseUser: FirebaseUser | null) => {
    if (!firebaseUser) {
      React.startTransition(() => {
        setUser(null);
      });
      return;
    }

    firstLoadRef.current = false;

    const idTokenResult = await firebaseUser.getIdTokenResult();
    await addFMCToken();

    await fetch('/api/login', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idTokenResult.token}`,
      },
    });

    React.startTransition(() => {
      setUser({
        ...firebaseUser,
        customClaims: filterStandardClaims({
          ...idTokenResult,
        }),
      });
    });

    if (firebaseUser && user && firebaseUser.uid === user.uid) {
      firstLoadRef.current = false;
      return;
    }

    React.startTransition(() => {
      const redirect = params?.get('redirect');
      window.location.href = redirect ?? HOME.url;
    });
  };

  const registerChangeListener = async () => {
    if (user && !user.emailVerified && firstLoadRef.current) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.customClaims.token}`,
      };

      await fetch(Endpoints.notification.appEngines.resendVerificationLink(), {
        body: JSON.stringify({
          id: user.uid,
          email: user.email,
        }),
        method: 'POST',
        headers,
      });
    }

    return onIdTokenChanged(getFirebaseAuth, handleIdTokenChanged);
  };

  React.useEffect(() => {
    const unsubscribePromise = registerChangeListener();
    return () => {
      unsubscribePromise.then((unsubscribe) => unsubscribe());
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        withPasswordLoading,
        withGoogleLoading,
        withSSOLoading,
        userAuthLoading,
        passwordChangeLoading,
        emailChangeLoading,
        applyActionCodeLoading,
        applyActionCodeSuccess,
        sendPasswordResetEmailSuccess,
        resetPassword,
        updateUserPassword,
        updateUserEmail,
        reauthenticateUser,
        loginWithGoogle,
        logout: handleLogout,
        loginWithEmailAndPassword,
        signUpWithEmailAndPassword,
        loginWithSSO,
        applyActionCode,
        setError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
