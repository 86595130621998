'use client';

import type { UserInfo } from 'firebase/auth';
import type { Claims } from 'next-firebase-auth-edge/lib/auth/claims';
import { createContext } from 'react';

export interface User extends Omit<UserInfo, 'providerId'> {
  emailVerified: boolean;
  customClaims: Claims;
}

export interface AuthContextValue {
  user: User | null;
  reauthenticateUser: any;
  resetPassword: any;
  updateUserPassword: any;
  updateUserEmail: any;
  loginWithSSO: any;
  loginWithGoogle: any;
  applyActionCode: any;
  logout: any;
  loginWithEmailAndPassword: any;
  signUpWithEmailAndPassword: any;
  error: string | null;
  setError: any;
  withPasswordLoading: boolean;
  withGoogleLoading: boolean;
  withSSOLoading: boolean;
  userAuthLoading: boolean;
  passwordChangeLoading: boolean;
  applyActionCodeLoading: boolean;
  emailChangeLoading: boolean;
  applyActionCodeSuccess?: boolean;
  sendPasswordResetEmailSuccess?: boolean;
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  error: null,
  withPasswordLoading: false,
  withGoogleLoading: false,
  withSSOLoading: false,
  userAuthLoading: false,
  passwordChangeLoading: false,
  emailChangeLoading: false,
  applyActionCodeLoading: false,
  applyActionCodeSuccess: undefined,
  sendPasswordResetEmailSuccess: undefined,
  loginWithSSO: () => {},
  loginWithGoogle: () => {},
  applyActionCode: () => {},
  loginWithEmailAndPassword: () => {},
  signUpWithEmailAndPassword: () => {},
  reauthenticateUser: () => {},
  resetPassword: () => {},
  updateUserPassword: () => {},
  updateUserEmail: () => {},
  setError: () => {},
  logout: () => {},
});
