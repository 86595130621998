'use client';

import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { RouteNavsWithKey } from '@/shared/config/route-links.config';

export default function BreadCrump() {
  const [paths, setPaths] = useState<string[]>([]);
  const [Icon, setIcon] =
    useState<(props: React.SVGProps<SVGSVGElement>) => JSX.Element>();
  const pathname = usePathname();
  const params = useParams();

  const checkAndRemoveSlashFromStart = (path: string) => {
    if (path.endsWith('/')) {
      return path.slice(0, -1);
    }
    return path;
  };

  const checkAndRemoveSlashFromEnd = (path: string) => {
    if (path.endsWith('/')) {
      return path.slice(0, -1);
    }
    return path;
  };

  useEffect(() => {
    const keys = Object.keys(params ?? {});
    let paramAtIndexZero = '';
    if (params && keys[0] && typeof params[keys[0]] === 'string') {
      paramAtIndexZero = params[keys[0]] as string;
    }

    let newPathname = pathname;
    if (paramAtIndexZero)
      newPathname =
        pathname?.slice(0, pathname?.indexOf(paramAtIndexZero)) ?? pathname;

    if (newPathname) {
      const newPathnameSlashRemoved = checkAndRemoveSlashFromEnd(
        checkAndRemoveSlashFromStart(newPathname)
      );
      const newPaths = newPathnameSlashRemoved
        .split('/')
        .filter((path) => path.length > 0)
        .map((path) => `/${path}`);
      const linkedPaths: string[] = [];
      for (let i = 0; i < newPaths.length; i += 1) {
        if (linkedPaths.length > 0) {
          linkedPaths.push(`${linkedPaths[i - 1]}${newPaths[i]}`);
        } else if (newPaths[i]) linkedPaths.push(newPaths[i] ?? '');
      }

      setPaths(linkedPaths.filter((path) => path));
    }
  }, [pathname]);

  useEffect(() => {
    if (paths && paths.length > 0) {
      let index = 0;
      if (paths.length > 1) {
        index = paths.length - 1;
      }
      const icon = RouteNavsWithKey[paths[index] ?? '']?.icon;
      if (icon) {
        setIcon(icon as (props: React.SVGProps<SVGSVGElement>) => JSX.Element);
      }
    }
  }, [paths]);

  return (
    <div className="flex items-center gap-3 text-base sm:text-2xl">
      {Icon && <Icon className="w-4 stroke-2 xs:w-6" />}
      <div className="flex items-center gap-2">
        {paths.map((path, key) => (
          <div key={key}>
            {RouteNavsWithKey[path] && (
              <>
                {key === paths.length - 1 ? (
                  <span className="font-semibold xs:font-bold">
                    {RouteNavsWithKey[path]!.name}
                  </span>
                ) : (
                  <Link
                    href={RouteNavsWithKey[path]!.url}
                    key={key}
                    className="font-semibold xs:font-bold"
                  >
                    {RouteNavsWithKey[path]!.name}
                  </Link>
                )}
                {key !== paths.length - 1 && <span>/</span>}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
