'use client';

import { useMemo } from 'react';

import { settingNavigation } from '@/shared/config/sidebar-navigation.config';
import { useAuth } from '@/shared/hooks/useAuth';

import PageHeader from '../../page-header';
import LayoutContainer from './LayoutContainer';
import SettingTab from './tabs/SettingTab';

type SettingLayoutProps = {
  children: React.ReactNode;
};
export default function SettingLayout({ children }: SettingLayoutProps) {
  const { user: authUser } = useAuth();
  const navigation = useMemo(() => settingNavigation(authUser), [authUser]);
  return (
    <LayoutContainer navigation={navigation} layout="setting">
      <PageHeader />
      <SettingTab settingNavigation={navigation[0]?.items} />
      {children}
    </LayoutContainer>
  );
}
