import React from 'react';

import LogoWithToggler from './LogoWithToggler';
import SearchInput from './SearchInput';

type LeftSideContentProps = {
  isLargeScreen: boolean;
  onSidebarOpen: () => void;
};
export default function LeftSideContent({
  isLargeScreen,
  onSidebarOpen,
}: LeftSideContentProps) {
  return (
    <div className="flex w-full items-center gap-3">
      {!isLargeScreen && (
        <LogoWithToggler
          isLargeScreen={isLargeScreen}
          onSidebarOpen={onSidebarOpen}
        />
      )}
      <div className="hidden w-full pl-3 md:w-3/5 lg:w-1/2 xl:w-2/5 2xl:w-1/3">
        <SearchInput />
      </div>
    </div>
  );
}
