/* eslint-disable react/no-unescaped-entities */
import { Button, InputFile, Modal } from '@canvaza/serval-ui';
import {
  CheckCircleIcon,
  VideoCameraIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import Link from 'next/link';
import type { DragEvent, FormEvent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import TextInput from '@/common/component/forms/TextInput';
import { UPGRADE } from '@/shared/config/route-links.config';
import { useGetCollectionById } from '@/shared/firebase/client/hooks';
import { useActiveWorkspace } from '@/shared/hooks/useActiveWorkspace';
import useAttributes from '@/shared/hooks/useAttributes';
import { removeFileNameExtension } from '@/shared/utils/string';

import UpgradeMessageCard from '../../../workspace/UpgradeMessageCard';
import useUploadItem from '../../hooks/useUploadItem';

type ChildrenArgs = {
  onOpenItemUploadModal: () => void;
};
type ItemUploadRenderRenderProps = {
  children: (args: ChildrenArgs) => React.ReactNode;
  collectionId: string | null;
};
export default function ItemUploadRender({
  collectionId,
  children,
}: ItemUploadRenderRenderProps) {
  const [openUploadItemModal, setOpenUploadItemModal] = useState(false);
  const [fileEnter, setFileEnter] = useState(false);
  const [name, setName] = useState<string>('');
  const [uploadFile, setUploadFile] = useState<File>();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { canAddVideo, attributes, isFreePlan } = useAttributes();
  const { activeWorkspace } = useActiveWorkspace();
  const { data: collection, state: collectionState } = useGetCollectionById(
    collectionId,
    'parent-collection'
  );

  const {
    percent,
    isLoading,
    videoLength,
    isDurationLoading,
    onCancelUpload,
    onUploadItem,
  } = useUploadItem({
    file: uploadFile,
    name,
    parent: collection,
    onCloseUploadItemModal: () => setOpenUploadItemModal(false),
    onResetFile: () => setUploadFile(undefined),
  });

  const handleInputFileChange = useCallback(
    async (event: FormEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;

      if ((target.files as FileList)[0]) {
        const inputFile: File = (target.files as FileList)[0]!;
        setUploadFile(inputFile);
        const reader = new FileReader();
        reader.readAsDataURL(inputFile);
      }
    },
    [uploadFile]
  );

  const handleDropFileChange = useCallback(
    async (event: DragEvent) => {
      if (event.dataTransfer?.files) {
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
          setUploadFile(droppedFile);
          const reader = new FileReader();
          reader.readAsDataURL(droppedFile);
        }
      }
    },
    [uploadFile]
  );

  const handleBrowseFile = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (uploadFile) {
      setName(removeFileNameExtension(uploadFile.name));
    }
  }, [uploadFile]);

  return (
    <>
      {children({ onOpenItemUploadModal: () => setOpenUploadItemModal(true) })}
      <Modal
        open={openUploadItemModal}
        title="Upload Video"
        actions={
          <div className="flex w-full justify-end gap-2 p-2">
            <div className="flex flex-row gap-2">
              <Button size="large" onClick={onCancelUpload}>
                <span className="text-dark-500">Cancel</span>
              </Button>
              <Button
                size="large"
                variant="contained"
                disabled={
                  isLoading ||
                  activeWorkspace === null ||
                  !canAddVideo ||
                  isFreePlan ||
                  isDurationLoading ||
                  collectionState.isLoading ||
                  !videoLength.isValidLength
                }
                onClick={onUploadItem}
                className={clsx('bg-primary', {
                  'bg-primary-light/40': !uploadFile,
                })}
              >
                {percent > 0 ? `Uploading... ${percent}%` : 'Upload'}
              </Button>
            </div>
          </div>
        }
        onClose={onCancelUpload}
      >
        <div className="flex flex-col gap-3 px-2 pb-0 pt-1">
          {isFreePlan ? (
            <div className="flex flex-col items-start justify-center gap-3 rounded bg-success-light/10 px-4 py-3 xs:flex-row xs:items-center lg:gap-7 lg:p-6">
              <p className="text-xs lg:text-sm">
                This functionality isn't accessible on the free plan. To access
                it, please upgrade your plan.
              </p>

              <Link
                href={UPGRADE.url}
                className="rounded border border-success-light px-4 py-2"
              >
                <span className="text-xs text-success-light md:text-sm">
                  Upgrade
                </span>
              </Link>
            </div>
          ) : (
            <>
              {/* info about video */}
              {!canAddVideo ? (
                <UpgradeMessageCard
                  description={`Reach your full video potential: While current plan allows for ${attributes?.video.limit} videos, 
              consider upgrading to unlock unlimited storage and explore all the features.`}
                />
              ) : (
                <>
                  {!videoLength.isValidLength && (
                    <UpgradeMessageCard
                      description={`To upload videos longer than ${attributes?.video.duration} minutes, simply upgrade your plan. 
                  It's quick and easy, and you'll have access to even more awesome features!`}
                    />
                  )}
                </>
              )}
            </>
          )}

          <div className="flex flex-col items-start gap-2">
            <button
              className={clsx(
                'flex w-full items-center justify-center rounded-xl border-2 border-dashed border-primary-light/40 py-8',
                {
                  'border-primary-light/20': !canAddVideo || isFreePlan,
                  'border-[3px] border-primary-lighter': fileEnter,
                }
              )}
              onClick={handleBrowseFile}
              disabled={!canAddVideo || isFreePlan || isLoading}
              onDragOver={(e) => {
                e.preventDefault();
                setFileEnter(true);
              }}
              onDragLeave={() => {
                setFileEnter(false);
              }}
              onDragEnd={(e) => {
                e.preventDefault();
                setFileEnter(false);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setFileEnter(false);
                handleDropFileChange(e);
              }}
            >
              <div className="flex flex-col items-center gap-4">
                <div
                  className={clsx(
                    'flex w-32 flex-col items-center justify-center rounded-md border-2 border-warning-light py-3',
                    {
                      'border-warning-light/25': !canAddVideo || isFreePlan,
                    }
                  )}
                >
                  <VideoCameraIcon className="h-6 w-6" />
                  <InputFile
                    ref={inputRef}
                    disabled={!canAddVideo || isFreePlan}
                    id="file"
                    placeholder="Upload"
                    accept=".mp4, .webm"
                    style={{ display: 'none' }}
                    onChange={(e) => handleInputFileChange(e)}
                  />
                  <p>Video</p>
                </div>
                {activeWorkspace && uploadFile ? (
                  <div className="flex gap-2">
                    {videoLength.isValidLength ? (
                      <CheckCircleIcon
                        className={clsx('w-6 text-success-light')}
                      />
                    ) : (
                      <XCircleIcon className="w-6 text-error" />
                    )}
                    {uploadFile && (
                      <span className="font-semibold">{`Selected Video Length: ${videoLength.lengthInMin} minutes`}</span>
                    )}
                  </div>
                ) : (
                  <p className="text-dark-600">
                    Drag and drop your file or{' '}
                    <span className="text-primary-light/70">browser</span>
                  </p>
                )}
              </div>
            </button>
            {activeWorkspace &&
              typeof activeWorkspace.attributes?.video.duration ===
                'number' && (
                <p
                  className={clsx('text-sm font-medium', {
                    'text-error': !videoLength.isValidLength && uploadFile,
                  })}
                >{`The maximum video length for your current plan is ${activeWorkspace.attributes?.video.duration.toFixed(
                  2
                )} minutes.`}</p>
              )}
            <span className="text-xs text-dark-300/70">
              {`Only .mp4 and .webm files are allowed for upload.`}
            </span>

            {activeWorkspace &&
              activeWorkspace.attributes?.video.duration === 'inf' && (
                <p className="text-start text-xs">
                  You can upload as many videos as you want with your current
                  plan.
                </p>
              )}
          </div>

          <div className="flex w-full flex-col gap-7">
            <div className="flex w-full flex-col gap-3">
              <div className="flex items-center justify-start gap-2">
                <p>Video Name</p>
                <span className="text-xs text-dark-300/70">{'(Optional)'}</span>
              </div>
              <TextInput
                id="name"
                placeholder="Video Name"
                disabled={!canAddVideo || isFreePlan}
                fullWidth
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
