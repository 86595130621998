/* eslint-disable react/no-unescaped-entities */
import { Modal } from '@canvaza/serval-ui';
import React from 'react';

import { useRecorder } from '@/shared/hooks/useRecorder';
import { AllowDeniedDeviceIcon } from '@/shared/utils/svg';

export default function CameraDeniedInfoModal() {
  const { recorder, onRecorderChanged } = useRecorder();
  const { permissionState } = recorder;
  const closePermissionStateModal = () => {
    onRecorderChanged((prev) => ({
      ...prev,
      permissionStateModal: {
        ...prev.permissionStateModal,
        camera: false,
      },
    }));
  };
  return (
    <Modal
      open={permissionState.camera === 'denied'}
      title=""
      actions={<></>}
      onClose={closePermissionStateModal}
    >
      <div className="flex items-center gap-6 px-2 py-6">
        <AllowDeniedDeviceIcon className="h-60 w-72 fill-dark-light stroke-dark dark:fill-dark dark:stroke-dark-light" />

        <div className="flex flex-col gap-4">
          <p className="font-semibold">Canvaza is blocked from using camera</p>
          <div className="ml-2 list-decimal text-xs">
            <li>Click the lock icon in your browser's address bar </li>
            <li>Turn on camera</li>
          </div>
        </div>
      </div>
    </Modal>
  );
}
