'use client';

import type { FirestoreError } from 'firebase/firestore';
import { createContext } from 'react';

import type { IUserWorkspaceList } from '@/providers/UserWorkspacesProvider';

type UserWorkspacesContextValue = {
  workspaceList: IUserWorkspaceList[];
  state?: {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isSuccess: boolean;
    isRefetching: boolean;
    isFetched: boolean;
    error: FirestoreError | null;

    refetch: () => void;
  };
};

export const UserWorkspacesContext = createContext<UserWorkspacesContextValue>({
  workspaceList: [],
});
