'use client';

import { useGetUserById } from '@/shared/firebase/client/hooks/user.hooks';
import { useAuth } from '@/shared/hooks/useAuth';
import { CurrentUserContext } from '@/stores/currentUserContext';

type CurrentUserProviderProps = {
  children: React.ReactNode;
};

export default function CurrentUserProvider({
  children,
}: CurrentUserProviderProps) {
  const { user: authUser } = useAuth();

  const { data, state } = useGetUserById(authUser?.uid, 'current-user-detail');

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: data,
        state,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}
