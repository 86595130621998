import { Avatar, Menu, MenuItem, Modal, Tooltip } from '@canvaza/serval-ui';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LockClosedIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { forwardRef, useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { CONSTANTS } from '@/shared/config/constant';
import { NEW_WORKSPACE, WORKSPACE } from '@/shared/config/route-links.config';
import type {
  IViewWorkspace,
  IWorkspace,
  IWorkspaceMember,
} from '@/shared/firebase';
import { useActiveWorkspace } from '@/shared/hooks/useActiveWorkspace';
import { useAuth } from '@/shared/hooks/useAuth';
import { useUserWorkspaceList } from '@/shared/hooks/useUserWorkspaceList';

import DefaultWorkspaceRender from '../../renders/DefaultWorkspaceRender';
import WorkspaceSkeleton from './WorkspaceSkeleton';

type FormattedWorkspaceList = IViewWorkspace & {
  isPrivate: boolean;
  isOwner: boolean;
};
type WorkspaceListComponentProp = {
  workspace: FormattedWorkspaceList;
  activeSpace?: IWorkspace;
  onSelectWorkspace: () => void;
  onWorkspaceDetail: () => void;
};
export default forwardRef<HTMLDivElement>(function ShowActiveWorkspace(
  _props,
  ref
) {
  const [formattedWorkspaceList, setFormattedWorkspaceList] = useState<
    FormattedWorkspaceList[]
  >([]);
  const [formattingWorkspace, setFormattingWorkspace] = useState(false);
  const { user: authUser } = useAuth();
  const router = useRouter();
  const { activeWorkspace, state: activeWorkspaceState } = useActiveWorkspace();
  const { workspaceList, state: workspaceListState } = useUserWorkspaceList();
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState<IWorkspace | null>();
  const tablet = useMedia('(min-width: 640px)');
  const handleNewWorkspaceRoute = () => {
    router.push(NEW_WORKSPACE.url);
  };

  const WorkspaceListComponent = ({
    workspace,
    activeSpace,
    onSelectWorkspace,
    onWorkspaceDetail,
  }: WorkspaceListComponentProp) => {
    return (
      <button
        onClick={onSelectWorkspace}
        className="flex items-center justify-between gap-2 rounded-md pt-3 dark:border-dark-600"
      >
        <div className="flex w-full items-center justify-between gap-1">
          <div className="flex w-2/3 items-center gap-1">
            <div className="relative flex items-center">
              <Avatar
                src={`${CONSTANTS.WORKSPACE_LOGO_BASE_URL}%2F${workspace.id}%2Flogo?alt=media`}
                size="small"
                fallBackImageUrl="/images/fallback-image.png"
              />
              {workspace.isPrivate && (
                <LockClosedIcon className="absolute -bottom-0.5 right-1 w-4 fill-warning stroke-warning-light dark:fill-warning-light" />
              )}
            </div>
            <p
              className={clsx(
                'truncate text-sm',
                {
                  'text-primary-light font-semibold':
                    activeSpace && activeSpace?.id === workspace.id,
                },
                {
                  'text-dark-darker font-sm dark:text-dark-lighter':
                    !activeSpace || activeSpace?.id !== workspace.id,
                }
              )}
            >
              {workspace.name}
            </p>
          </div>

          {activeSpace && workspace.id === activeSpace?.id && (
            <MenuItem
              className="flex w-8 justify-center sm:w-12"
              icon={
                <Cog6ToothIcon className="w-4 fill-primary sm:w-6 dark:fill-primary-light" />
              }
              label=""
              onClick={onWorkspaceDetail}
            />
          )}
        </div>
      </button>
    );
  };

  const handleRouteToSetting = () => {
    if (!activeWorkspace) return;
    router.push(`${WORKSPACE.url}/${activeWorkspace?.id}`);
  };

  useEffect(() => {
    setFormattingWorkspace(true);
    if (workspaceListState?.isSuccess) {
      const newFormattedWorkspaceList: FormattedWorkspaceList[] =
        workspaceList.map((val) => {
          if (val.type === 'workspace') {
            const loadedWorkspace = val.workspace as IWorkspace;
            return {
              id: loadedWorkspace.id,
              name: loadedWorkspace.name,
              isOwner: authUser?.uid === loadedWorkspace.user.id,
              isPrivate: loadedWorkspace.personal,
            } as FormattedWorkspaceList;
          }
          const loadedWorkspaceMember = val.workspace as IWorkspaceMember;
          return {
            id: loadedWorkspaceMember.workspace.id,
            name: loadedWorkspaceMember.workspace.name,
            isOwner: false,
            isPrivate: false,
          } as FormattedWorkspaceList;
        });

      setFormattedWorkspaceList(newFormattedWorkspaceList);
    }

    setFormattingWorkspace(false);
  }, [workspaceListState?.isSuccess, workspaceList]);

  useEffect(() => {
    if (activeWorkspaceState?.isSuccess && workspaceListState?.isSuccess) {
      setActive(activeWorkspace);
    }
  }, [
    activeWorkspaceState?.isSuccess,
    activeWorkspace,
    workspaceListState?.isSuccess,
  ]);

  useEffect(() => {
    if (activeWorkspaceState?.isSuccess) {
      if (active === null) {
        setOpenModal(true);
      }
    }
  }, [active, activeWorkspaceState?.isSuccess]);

  return (
    <div className="flex w-full">
      {(activeWorkspaceState?.isLoading ||
        formattingWorkspace ||
        workspaceListState?.isLoading) && <WorkspaceSkeleton />}
      {activeWorkspaceState?.isSuccess &&
        workspaceListState?.isSuccess &&
        !formattingWorkspace &&
        activeWorkspace && (
          <div className="w-full">
            <Tooltip
              content={activeWorkspace.name}
              placement="left"
              arrow={false}
            >
              <Menu
                className="outline-0"
                icon={
                  <div className="flex w-full items-center justify-between gap-2 rounded-md p-0.5 text-dark transition duration-300 dark:text-dark-light">
                    <div className="flex items-center justify-start gap-2">
                      <Avatar
                        src={`${CONSTANTS.WORKSPACE_LOGO_BASE_URL}%2F${activeWorkspace.id}%2Flogo?alt=media`}
                        fallBackImageUrl="/images/fallback-image.png"
                      />
                      <div className="flex flex-col items-start justify-start">
                        <p className="w-36 self-start truncate rounded-md text-left text-lg font-bold">
                          {activeWorkspace.name}
                        </p>
                        {activeWorkspace.personal ? (
                          <span className="text-xs text-dark-700 opacity-60 dark:text-dark-light">
                            Personal Workspace
                          </span>
                        ) : (
                          <span className="text-xs text-dark-700 opacity-60 dark:text-dark-light">
                            Sharable Workspace
                          </span>
                        )}
                      </div>
                    </div>
                    {tablet ? (
                      <ChevronRightIcon className="w-6 stroke-dark dark:stroke-dark-light" />
                    ) : (
                      <ChevronDownIcon className="w-6 stroke-dark dark:stroke-dark-light" />
                    )}
                  </div>
                }
                placement={tablet ? 'left-start' : 'bottom-end'}
              >
                <div
                  ref={ref}
                  className="flex w-56 flex-col items-start gap-4 pt-2 sm:w-80"
                >
                  <h2 className="pl-3 text-lg font-semibold">Workspaces</h2>
                  <div className="flex h-fit max-h-[60vh] w-full flex-col gap-2 overflow-y-auto pl-3">
                    {formattedWorkspaceList.map((workspace, index) => (
                      <DefaultWorkspaceRender
                        key={index}
                        workspaceId={workspace.id}
                      >
                        {({ handleRouteToDefaultWorkspace }) => (
                          <WorkspaceListComponent
                            workspace={workspace}
                            activeSpace={activeWorkspace}
                            onSelectWorkspace={handleRouteToDefaultWorkspace}
                            onWorkspaceDetail={handleRouteToSetting}
                          />
                        )}
                      </DefaultWorkspaceRender>
                    ))}
                  </div>

                  <div className="flex w-full items-center gap-4 bg-primary-light/10 p-3">
                    <MenuItem
                      label="Add workspace"
                      icon={<PlusIcon className="w-6" />}
                      onClick={handleNewWorkspaceRoute}
                      className="flex items-center gap-4"
                    />
                  </div>
                </div>
              </Menu>
            </Tooltip>
          </div>
        )}
      <Modal
        title="Select Your Default Workspace"
        open={
          openModal &&
          active === null &&
          activeWorkspaceState?.isSuccess &&
          !activeWorkspaceState.isLoading
        }
        onClose={() => setOpenModal(false)}
        actions={<div className="flex w-full justify-end px-3 py-2"></div>}
      >
        <div className="flex w-full flex-col gap-6 px-4 py-2">
          <div className="rounded-md bg-error-lighter/20 p-3">
            <p className="text-sm">
              {
                "Your default workspace can't be accessed. Please select another workspace"
              }
            </p>
          </div>
          <div className="flex w-full flex-col gap-4">
            {formattedWorkspaceList.map((workspace, index) => (
              <DefaultWorkspaceRender key={index} workspaceId={workspace.id}>
                {({ handleRouteToDefaultWorkspace }) => (
                  <WorkspaceListComponent
                    workspace={workspace}
                    onSelectWorkspace={handleRouteToDefaultWorkspace}
                    onWorkspaceDetail={handleRouteToSetting}
                  />
                )}
              </DefaultWorkspaceRender>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
});
