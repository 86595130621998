import { Bars3Icon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';

import { HOME, ROOT } from '@/shared/config/route-links.config';
import { useAuth } from '@/shared/hooks/useAuth';

import CanvazaLogo from '../../../CanvazaLogo';

type LogoWithTogglerProps = {
  isLargeScreen: boolean;
  onSidebarOpen: () => void;
};
export default function LogoWithToggler({
  isLargeScreen,
  onSidebarOpen,
}: LogoWithTogglerProps) {
  const { user: authUser } = useAuth();

  return (
    <div className="flex items-center gap-3">
      {!isLargeScreen && (
        <button
          className={clsx(
            'translate-x-2 rounded text-dark-darker dark:text-dark-300',
            'hover:bg-primary hover:text-dark-lighter dark:hover:bg-primary-light dark:hover:text-dark-lighter'
          )}
          onClick={onSidebarOpen}
        >
          <Bars3Icon className="w-6" />
        </button>
      )}

      <CanvazaLogo href={authUser ? HOME.url : ROOT.url} />
    </div>
  );
}
