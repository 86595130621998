import { Modal } from '@canvaza/serval-ui';
import React from 'react';

import { useRecorder } from '@/shared/hooks/useRecorder';

export default function NoCameraInfoModal() {
  const { recorder, onRecorderChanged } = useRecorder();
  const { noDeviceInputs } = recorder;

  const closeNoCameraInfoModal = () => {
    onRecorderChanged((prev) => ({
      ...prev,
      noDeviceInputs: {
        ...prev.noDeviceInputs,
        camera: false,
      },
    }));
  };
  return (
    <Modal
      open={noDeviceInputs?.camera}
      onClose={closeNoCameraInfoModal}
      title=""
      size="small-x"
      actions={<></>}
    >
      <p className="px-2 py-6">
        Check your system settings to make sure that a camera is available. If
        not, plug one in. You might then need to restart your browser.
      </p>
    </Modal>
  );
}
