'use client';

import { Button } from '@canvaza/serval-ui';
import { useEffect, useState } from 'react';

import { ClientToastProvider } from '@/app/ClientImports';
import { OfflineIcon } from '@/shared/utils/svg';

type AppProviderProps = {
  children: any;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  if (!isOnline) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="flex w-full flex-col items-center gap-5 sm:w-11/12 lg:w-3/5">
          <OfflineIcon className="w-24 fill-dark-400 stroke-1 dark:fill-dark-light" />
          <p className="px-6 text-center text-lg font-semibold">
            No internet connection found.
          </p>
          <p className=""> Check your connection and try again.</p>
          <Button variant="contained" size="large" onClick={handleRefresh}>
            Refresh
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="relative h-screen w-full overflow-hidden bg-dark-lighter text-dark antialiased xl:flex dark:bg-dark-600 dark:text-dark-lighter">
      <ClientToastProvider>{children}</ClientToastProvider>
    </div>
  );
};
