import { serverTimestamp } from 'firebase/firestore';
import getBlobDuration from 'get-blob-duration';
import { useEffect, useState } from 'react';

import { getItemUid } from '@/shared/firebase/client/functions/item.functions';
import { useCreateItem } from '@/shared/firebase/client/hooks/item.hooks';
import { useUploadFile } from '@/shared/firebase/client/hooks/storage.hooks';
import type { ICollection } from '@/shared/firebase/models/collection.interface';
import type { IItem } from '@/shared/firebase/models/item.interface';
import useAttributes from '@/shared/hooks/useAttributes';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { removeFileNameExtension } from '@/shared/utils/string';
import { microSecondsToMinute } from '@/shared/utils/time-formatter';
import { getVideoCover } from '@/shared/utils/video';

const initialVideoLengthState = {
  lengthInMs: 0,
  lengthInMin: '0.00',
  isValidLength: true,
};

type UploadHookArgs = {
  file?: File;
  name?: string;
  parent?: ICollection | null;
  onCloseUploadItemModal?: () => void;
  onResetFile: () => void;
};
export default function useUploadItem(args: UploadHookArgs) {
  const [percent, setPercent] = useState<number>(0);

  const [docId, setDocId] = useState(getItemUid());
  const [isLoading, setIsLoading] = useState(false);
  const [isDurationLoading, setIsDurationLoading] = useState(false);
  const [videoLength, setVideoLength] = useState(initialVideoLengthState);

  const { currentUser } = useCurrentUser();
  const { create, state: createState } = useCreateItem(docId);
  const { isValidDuration } = useAttributes();

  const {
    parent,
    file,
    name: videoName,
    onResetFile,
    onCloseUploadItemModal,
  } = args;

  const { uploadTask: itemUploadTask, uploadFile: uploadItem } = useUploadFile(
    `files/${docId}/item`
  );

  const { uploadTask: coverUploadTask, uploadFile: uploadCover } =
    useUploadFile(`files/${docId}/cover`);

  useEffect(() => {
    if (file) {
      setIsDurationLoading(true);
      getBlobDuration(file).then((val) => {
        const duration = typeof val === 'number' ? val : 0;
        setVideoLength({
          lengthInMs: typeof val === 'number' ? val : 0,
          lengthInMin: microSecondsToMinute(duration),
          isValidLength: isValidDuration(duration),
        });
        setIsDurationLoading(false);
      });
    }
  }, [file]);

  const resetVideoLength = () => {
    setVideoLength(initialVideoLengthState);
  };

  const resetUploadData = () => {
    setPercent(0);
    setIsLoading(false);
    setDocId(getItemUid());
    resetVideoLength();
    if (onCloseUploadItemModal) onCloseUploadItemModal();
    onResetFile();
  };

  useEffect(() => {
    if (createState.isSuccess) {
      resetUploadData();
    }
  }, [createState.isSuccess]);

  const handleUploadItem = async () => {
    if (!currentUser || videoLength.lengthInMs < 0) return;
    if (file) {
      setIsLoading(true);
      const cover = (await getVideoCover(file)) as Blob;

      const { name } = file;
      const newItem: IItem = {
        id: docId,
        name: videoName || removeFileNameExtension(name),
        duration: Number(videoLength.lengthInMs),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        collection: null,
        user: {
          id: currentUser.id,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
        },
        privacy: {
          allow: {
            comment: true,
            reaction: true,
            download: true,
            viewAnalysis: true,
          },
        },
        starredUsers: [],
        status: {
          trashed: false,
          direct: false,
        },
        workspaceId: currentUser.defaultWorkspaceId,
        keywords: null,
      };

      if (parent) {
        newItem.collection = { id: parent.id, status: parent.status };
      }

      uploadItem(file);
      uploadCover(cover);

      itemUploadTask.current?.on(
        'state_changed',
        (snapshot: { bytesTransferred: number; totalBytes: number }) => {
          const perc = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(perc);
        },
        (_error: any) => {},
        () => {
          create(newItem);
        }
      );
    }
  };

  const handleCancelUpload = () => {
    if (isLoading) {
      itemUploadTask.current?.cancel();
      coverUploadTask.current?.cancel();
    }
    resetUploadData();
  };

  return {
    percent,
    isLoading: isLoading || createState.isLoading,
    isDurationLoading,
    videoLength,
    onUploadItem: handleUploadItem,
    onCancelUpload: handleCancelUpload,
  };
}
