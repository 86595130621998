import { Modal } from '@canvaza/serval-ui';
import React from 'react';

import { useRecorder } from '@/shared/hooks/useRecorder';
import { AllowPromptDeviceIcon } from '@/shared/utils/svg';

export default function MicPromptInfoModal() {
  const { recorder, onRecorderChanged } = useRecorder();
  const { permissionState, permissionStateModal } = recorder;
  const closePermissionStateModal = () => {
    onRecorderChanged((prev) => ({
      ...prev,
      permissionStateModal: {
        ...prev.permissionStateModal,
        mic: false,
      },
    }));
  };
  return (
    <Modal
      open={permissionState?.mic === 'prompt' && permissionStateModal?.mic}
      title=""
      actions={<></>}
      onClose={closePermissionStateModal}
    >
      <div className="flex flex-col items-center justify-center gap-3">
        <AllowPromptDeviceIcon className="h-60 w-72 fill-dark-light stroke-dark dark:fill-dark dark:stroke-dark-light" />
        <span>
          Click <span className="font-bold">Allow</span>
        </span>
        <p>You can still turn off your mic anytime in the record</p>
      </div>
    </Modal>
  );
}
