import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

import ItemContainer from '@/common/widgets/containers/ItemContainer';

import type { SideNavigationItemProps } from '../../types';

type SettingTabProps = {
  settingNavigation?: SideNavigationItemProps[];
};
export default function SettingTab({ settingNavigation }: SettingTabProps) {
  const pathname = usePathname();
  return (
    <ItemContainer className="relative w-full lg:hidden">
      <div className="flex items-center gap-2 overflow-x-auto overflow-y-hidden py-4">
        {settingNavigation &&
          settingNavigation.map((nav, key) => (
            <div key={key} className="flex items-center gap-2">
              <Link
                href={nav.url}
                className={clsx(
                  'whitespace-nowrap text-sm text-dark hover:font-bold dark:text-dark-light',
                  {
                    'border-b-4 border-dark-600 dark:border-dark-light':
                      pathname?.startsWith(nav.url),
                  }
                )}
              >
                {nav.name}
              </Link>
              {key !== settingNavigation.length - 1 && <span>|</span>}
            </div>
          ))}
      </div>
    </ItemContainer>
  );
}
