import React from 'react';

import { useRecorder } from '@/shared/hooks/useRecorder';

import BothDeniedOrPromptInfoModal from './BothDeniedOrPromptInfoModal';
import CameraDeniedInfoModal from './CameraDeniedInfoModal';
import CameraPromptInfoModal from './CameraPromptInfoModal';
import MicDeniedInfoModal from './MicDeniedInfoModal';
import MicPromptInfoModal from './MicPromptInfoModal';
import NoCameraInfoModal from './NoCameraInfoModal';
import NoMicInfoModal from './NoMicInfoModal';

type DeviceAccessStatusModalsProps = { onStartRecording?: () => void };
export default function DeviceAccessStatusModals({
  onStartRecording,
}: DeviceAccessStatusModalsProps) {
  const { recorder } = useRecorder();
  const { permissionState, permissionStateModal, noDeviceInputs } = recorder;

  if (permissionState?.camera === 'denied' && permissionStateModal?.camera)
    return <CameraDeniedInfoModal />;
  if (permissionState?.mic === 'denied' && permissionStateModal?.mic)
    return <MicDeniedInfoModal />;
  if (permissionState?.camera === 'prompt' && permissionStateModal?.camera)
    return <CameraPromptInfoModal />;
  if (permissionState?.mic === 'prompt' && permissionStateModal?.mic)
    return <MicPromptInfoModal />;
  if (noDeviceInputs?.mic) return <NoMicInfoModal />;
  if (noDeviceInputs?.camera) return <NoCameraInfoModal />;
  if (
    (permissionState.camera === 'denied' ||
      permissionState.camera === 'prompt') &&
    (permissionState.mic === 'denied' || permissionState.mic === 'prompt') &&
    permissionStateModal.both
  )
    return <BothDeniedOrPromptInfoModal onStartRecording={onStartRecording} />;
  return null;
}
