import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

import CurrentUserAvatarWithBasicProfile from '@/app/(protected)/(common)/(settings)/setting/personal/CurrentUserAvatarWithBasicProfile';
import ShowActiveWorkspace from '@/app/(protected)/(user)/workspace/active-workspace/show-active-workspace';
import useCustomOutsideClick from '@/common/component/recorder/recorder-settings/setting-modal/hooks/useCustomOutsideClick';
import ItemContainer from '@/common/widgets/containers/ItemContainer';

import type { WithGroupedProps } from '../../types';
import LogoWithToggler from '../appbar/left-side-contents/LogoWithToggler';
import type { LayoutType } from '../LayoutContainer';
import NavItemsList from './nav-items-list';

type SidebarProps = {
  layout: LayoutType;
  open: boolean;
  width?: number;
  isLargeScreen?: boolean;
  navigation?: WithGroupedProps[];
  onSideBarOpen: () => void;
  onClose: (withClickAway?: boolean) => void;
};

const Sidebar = ({
  open,
  layout,
  width = 264,
  isLargeScreen,
  navigation,
  onSideBarOpen,
  onClose,
}: SidebarProps) => {
  const sidebarContainerRef = React.useRef<HTMLDivElement>(null);
  const workspaceMenuRef = React.useRef<HTMLDivElement>(null);

  useCustomOutsideClick(
    sidebarContainerRef,
    () => onClose(true),
    workspaceMenuRef
  );
  const sidebarTransitionClasses = !isLargeScreen
    ? {
        enter: 'transform transition ease-in-out duration-500 sm:duration-700',
        enterFrom: '-translate-x-full',
        enterTo: 'translate-x-0',
        leave: 'transform transition ease-in-out duration-500 sm:duration-700',
        leaveFrom: 'translate-x-0',
        leaveTo: '-translate-x-full',
      }
    : null;

  return (
    <Transition.Root
      show={open}
      className={clsx({
        'fixed inset-0 z-30 bg-dark-400/65 dark:bg-dark-700/70': !isLargeScreen,
      })}
    >
      <Transition.Child
        className={clsx('fixed left-0 z-30 flex h-full xl:static')}
        {...sidebarTransitionClasses}
      >
        <div
          ref={sidebarContainerRef}
          className={clsx(
            'overflow-x-hidden rounded-none border-0 bg-dark-lighter p-0 hover:overflow-y-auto dark:bg-dark-600 dark:shadow-dark-500',
            'transition duration-0'
          )}
          style={{ width: `${width}px` }}
        >
          <div
            style={{ width: `${width - 0}px` }}
            className="flex h-full flex-col justify-between gap-3"
          >
            <ItemContainer className="flex h-full flex-col justify-between gap-3 !rounded-[0rem] bg-[#45AFFF]/10 dark:!rounded-[0rem]">
              <div className="flex flex-col gap-6">
                <div
                  className={clsx(
                    'flex h-[7%] justify-start transition-all delay-700 duration-1000 ease-in-out',
                    {
                      'translate-x-3 opacity-100': isLargeScreen || open,
                      '-translate-x-3 opacity-0': !isLargeScreen || !open,
                    }
                  )}
                >
                  <LogoWithToggler
                    isLargeScreen={isLargeScreen !== undefined ?? isLargeScreen}
                    onSidebarOpen={onSideBarOpen}
                  />
                </div>
                <div className="flex flex-col gap-6 pt-6">
                  {layout === 'workspace' && (
                    <ShowActiveWorkspace ref={workspaceMenuRef} />
                  )}
                  {layout === 'setting' && (
                    <CurrentUserAvatarWithBasicProfile pageType="Sidebar" />
                  )}
                  {layout === 'admin' && (
                    <span className="text-2xl font-semibold">
                      Canvaza Admin
                    </span>
                  )}
                  <NavItemsList
                    isLargeScreen={isLargeScreen}
                    onClose={onClose}
                    navigation={navigation}
                  />
                </div>
              </div>
            </ItemContainer>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

export default Sidebar;
