import Link from 'next/link';
import React from 'react';

import { UPGRADE } from '@/shared/config/route-links.config';

type UpgradeMessageCardProps = {
  description: string;
  upgradeButton?: boolean;
};
export default function UpgradeMessageCard({
  description,
  upgradeButton,
}: UpgradeMessageCardProps) {
  return (
    <div className="flex flex-col items-start justify-center gap-3 rounded bg-success-light/10 px-4 py-3  xs:flex-row xs:items-center lg:gap-7 lg:p-6">
      <p className="text-xs lg:text-sm">{description}</p>
      {upgradeButton && (
        <Link
          href={UPGRADE.url}
          className="rounded border border-success-light px-4 py-2"
        >
          <span className="text-xs text-success-light md:text-sm">Upgrade</span>
        </Link>
      )}
    </div>
  );
}
