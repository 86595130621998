import { Modal } from '@canvaza/serval-ui';
import React from 'react';

import { useRecorder } from '@/shared/hooks/useRecorder';

export default function NoMicInfoModal() {
  const { recorder, onRecorderChanged } = useRecorder();
  const { noDeviceInputs } = recorder;

  const closeNoMicInfoModal = () => {
    onRecorderChanged((prev) => ({
      ...prev,
      noDeviceInputs: {
        ...prev.noDeviceInputs,
        mic: false,
      },
    }));
  };
  return (
    <Modal
      open={noDeviceInputs?.mic}
      onClose={closeNoMicInfoModal}
      title="Can't find your microphone"
      size="small-x"
      actions={<></>}
    >
      <p className="px-2 py-6">
        Check your system settings to make sure that a microphone is available.
        If not, plug one in. You might then need to restart your browser.
      </p>
    </Modal>
  );
}
