import React from 'react';

import { useAuth } from '@/shared/hooks/useAuth';

import Header from '../external/header';
import type { WithGroupedProps } from '../types';
import AppBar from './appbar';
import Sidebar from './sidebar';
import { useSidebar } from './sidebar/hooks/useSidebar';

export type LayoutType = 'workspace' | 'admin' | 'setting';
type LayoutContainerProps = {
  navigation?: WithGroupedProps[];
  layout: LayoutType;
  children: React.ReactNode;
};
export default function LayoutContainer({
  layout,
  navigation,
  children,
}: LayoutContainerProps) {
  const { user: authUser } = useAuth();
  const { open, onOpen, onClose, isWide } = useSidebar();
  return (
    <>
      {authUser && (
        <Sidebar
          layout={layout}
          width={250}
          open={open}
          onClose={onClose}
          isLargeScreen={isWide}
          navigation={navigation}
          onSideBarOpen={onOpen}
        />
      )}

      <div className="relative h-screen w-full overflow-auto">
        <div className="sticky top-0 h-[7%]  w-full">
          {authUser ? (
            <AppBar isLargeScreen={isWide} onSidebarOpen={onOpen} />
          ) : (
            <Header />
          )}
        </div>
        <main className="flex h-[93%] w-full gap-0 transition duration-300">
          <div className="flex w-full flex-col">
            <div className="flex h-full w-full flex-col gap-8 overflow-y-auto px-2 pb-3 pt-2 sm:px-6 sm:pb-6 sm:pt-4">
              {children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
