import React, { useEffect } from 'react';

import { LIBRARY } from '@/shared/config/route-links.config';
import { useUpdateUser } from '@/shared/firebase/client/hooks';
import { useAuth } from '@/shared/hooks/useAuth';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

type DefaultWorkspaceRenderChildrenArgs = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isDisabled: boolean;
  handleRouteToDefaultWorkspace: () => void;
};

type DefaultWorkspaceRenderProps = {
  workspaceId?: string | null;
  children: (args: DefaultWorkspaceRenderChildrenArgs) => React.ReactNode;
};
export default function DefaultWorkspaceRender({
  workspaceId,
  children,
}: DefaultWorkspaceRenderProps) {
  const { user: authUser } = useAuth();

  const { update: updateUser, state: updateUserState } = useUpdateUser(
    authUser?.uid
  );

  const { currentUser, state: currentUserState } = useCurrentUser();

  const handleRouteToDefaultWorkspace = () => {
    if (!authUser || !currentUser || !workspaceId) return;
    updateUser({
      ...currentUser,
      defaultWorkspaceId: workspaceId,
    });
  };

  useEffect(() => {
    if (updateUserState.isSuccess) {
      window.location.pathname = LIBRARY.url;
    }
  }, [updateUserState.isSuccess]);

  return (
    <>
      {children({
        isError: updateUserState.isError,
        isLoading: updateUserState.isLoading,
        isSuccess: updateUserState.isSuccess,
        isDisabled:
          updateUserState.isLoading ||
          (currentUserState?.isLoading ?? false) ||
          !workspaceId,
        handleRouteToDefaultWorkspace,
      })}
    </>
  );
}
