'use client';

import { useMemo } from 'react';

import { adminNavigation } from '@/shared/config/sidebar-navigation.config';
import useAdminAbility from '@/shared/hooks/useAdminAbility';

import PageHeader from '../../page-header';
import LayoutContainer from './LayoutContainer';

type AdminLayoutProps = {
  children: React.ReactNode;
};
export default function AdminLayout({ children }: AdminLayoutProps) {
  const ability = useAdminAbility();

  const navigation = useMemo(() => adminNavigation(ability), [ability]);
  return (
    <LayoutContainer navigation={navigation} layout="admin">
      <PageHeader />
      {children}
    </LayoutContainer>
  );
}
