/* eslint-disable import/no-cycle */
import React from 'react';

import CanvazaMediaRecorder from './canvaza-media-recorder';
import type { VideoSettingOptions } from './media-recorder';

export enum VideoSetting {
  'camera_only' = 'Camera only',
  'camera_and_screen' = 'Camera and screen',
  'screen_only' = 'Screen only',
}

export enum VideoFileFormat {
  'mp4' = 'mp4',
  'webm' = 'webm',
}

export type VideoSettingType = keyof typeof VideoSetting;
export type VideoFileFormatType = keyof typeof VideoFileFormat;
export type RecorderRefType = {
  onStartRecording: () => void;
  onStopRecording: () => void;
  onPauseRecording: () => void;
  onResumeRecording: () => void;
  onEnableCamera: () => void;
  onDisableCamera: () => void;
  onManuallyEnableCamera: (manually?: boolean) => void;
  onManuallyDisableCamera: (manually?: boolean) => void;
  onEnableMic: () => void;
  onMuteAudio: () => void;
  onUnMuteAudio: () => void;
  isAudioMuted: boolean;
  videoSettingOption: VideoSettingOptions;
};

export type DeviceStatus = {
  camera: boolean;
  audioMuted: boolean;
};

export type RecorderSettings = {
  videoSourceId?: string;
  audioSourceId?: string;
  videoSetting: VideoSettingType;
  outPutFileFormat: VideoFileFormatType;
};

export default function Recorder() {
  return <CanvazaMediaRecorder />;
}
