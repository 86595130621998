import type { DocumentData, QueryConstraint } from 'firebase/firestore';
import {
  deleteDoc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

import type { Collection } from '../../constants/collection';
import type { IGetAllQueryResult, IGetByIdQueryResult } from '../types';
import { converter } from '../utils/converter';
import { getDocId } from '../utils/doc-id';
import { documentReference, queryReference } from '../utils/reference';

export const getAllQuery = async <T extends DocumentData>(
  collectionName: Collection,
  queryConstraint: QueryConstraint[]
): Promise<IGetAllQueryResult<T>> => {
  const queryRef = queryReference<T>(collectionName, queryConstraint);

  const docsSnapshot = await getDocs(queryRef);

  const docsT = docsSnapshot.docs.map((val) => val.data());
  return {
    data: docsT,
    count: docsSnapshot.size,
  };
};

export const getByIdQuery = async <T extends DocumentData>(
  collectionName: Collection,
  id: string
): Promise<IGetByIdQueryResult<T>> => {
  const docRef = documentReference<T>(collectionName, id);
  const docSnapshot = await getDoc(docRef);
  const document = docSnapshot.data() ?? null;
  return {
    data: document,
  };
};

export const createDocument = async <T extends DocumentData>(
  collectionName: string,
  data: T
): Promise<void> => {
  const id = getDocId(collectionName);

  const docRef = documentReference<T>(
    collectionName,
    data.id ?? id
  ).withConverter(converter<T>());
  await setDoc(docRef, {
    ...data,
    id: data.id ?? id,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

export const updateDocument = async <T extends DocumentData>(
  collectionName: string,
  data: T,
  id: string
): Promise<void> => {
  const docRef = documentReference<T>(collectionName, id).withConverter(
    converter<T>()
  );
  await setDoc(
    docRef,
    { ...data, updatedAt: serverTimestamp() },
    { merge: true }
  );
};

export const deleteDocument = async <T extends DocumentData>(
  collectionName: string,
  id: string
): Promise<void> => {
  const docRef = documentReference<T>(collectionName, id).withConverter(
    converter<T>()
  );
  await deleteDoc(docRef);
};
