'use client';

import { createContext } from 'react';

type DeviceInputStatus = {
  camera: boolean;
  mic: boolean;
};

type DevicePermissionState = {
  camera: PermissionState;
  mic: PermissionState;
};
export interface RecorderType {
  file: File | null;
  url: string | null;
  isRouting: boolean;
  isCancelled: boolean;
  show: boolean;
  permissionState: DevicePermissionState;
  permissionStateModal: DeviceInputStatus & { both: boolean };
  noDeviceInputs: DeviceInputStatus;
}

export interface RecordContextType {
  recorder: RecorderType;
  onRecorderChanged: React.Dispatch<React.SetStateAction<RecorderType>>;
}

export const RecordContext = createContext<RecordContextType>({
  recorder: {
    file: null,
    url: null,
    isCancelled: false,
    isRouting: false,
    show: false,
    permissionState: {
      camera: 'granted',
      mic: 'granted',
    },
    permissionStateModal: {
      camera: false,
      mic: false,
      both: false,
    },
    noDeviceInputs: {
      camera: false,
      mic: false,
    },
  },
  onRecorderChanged: () => {},
});
