'use client';

import { useEffect, useState } from 'react';

import { useGetWorkspaceById } from '@/shared/firebase/client/hooks';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { ActiveWorkspaceContext } from '@/stores/activeWorkspaceContext';

type ActiveWorkspaceProviderProps = {
  children: React.ReactNode;
};

export default function ActiveWorkspaceProvider({
  children,
}: ActiveWorkspaceProviderProps) {
  const [defaultWorkspaceId, setDefaultWorkspaceId] = useState<string | null>();
  const { currentUser, state: currentUserState } = useCurrentUser();
  const { data: workspace, state: workspaceState } = useGetWorkspaceById(
    defaultWorkspaceId,
    'get-current-workspace'
  );

  useEffect(() => {
    if (currentUserState?.isSuccess) {
      setDefaultWorkspaceId(currentUser?.defaultWorkspaceId);
    }
  }, [currentUserState?.isSuccess]);

  return (
    <ActiveWorkspaceContext.Provider
      value={{
        activeWorkspace: workspace,
        state: {
          ...workspaceState,
          isLoading: currentUserState?.isLoading || workspaceState.isLoading,
          isSuccess: currentUserState?.isSuccess || workspaceState.isSuccess,
          isError: currentUserState?.isError || workspaceState.isError,
        },
      }}
    >
      {children}
    </ActiveWorkspaceContext.Provider>
  );
}
