'use client';

import React, { useEffect, useState } from 'react';

import {
  MEMBER_ROLE_PERMISSIONS,
  MemberRole,
} from '@/shared/config/members-roles.config';
import { useWorkspaceMember } from '@/shared/hooks/useWorkspaceMember';
import { buildAbilityFor } from '@/shared/user-casl/ability';
import { AbilityContext } from '@/shared/user-casl/Can';
import type { RawRuleTypes } from '@/shared/user-casl/types';

type UserAbilityProviderProps = {
  children: React.ReactNode;
};
export default function UserAbilityProvider({
  children,
}: UserAbilityProviderProps) {
  const {
    workspaceMember,
    role,
    state: workspaceMemberState,
  } = useWorkspaceMember();
  const [rolePermissions, setRolePermissions] = useState<RawRuleTypes[]>([]);

  const formattedPermissions = (permissions: string[]) =>
    permissions.map((permission) => {
      const actionsSubjects = permission.split('-');
      return { action: actionsSubjects[0]!, subject: actionsSubjects[1]! };
    });

  useEffect(() => {
    if (workspaceMemberState?.isSuccess) {
      if (role) {
        const newPermissions: string[] = [...MEMBER_ROLE_PERMISSIONS[role]];
        if (workspaceMember?.permissions) {
          newPermissions.push(...workspaceMember.permissions);
        }
        setRolePermissions(formattedPermissions([...newPermissions]));
      } else {
        setRolePermissions(
          formattedPermissions(MEMBER_ROLE_PERMISSIONS[MemberRole.Guest])
        );
      }
    }
  }, [workspaceMemberState?.isSuccess, role]);

  return (
    <AbilityContext.Provider value={buildAbilityFor(rolePermissions)}>
      {children}
    </AbilityContext.Provider>
  );
}
