/* eslint-disable consistent-return */
import type { MessagePayload } from 'firebase/messaging';
import { getMessaging, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';

import { getFirebaseApp } from '../../config/firebase.config';
import useFCMToken from './useFCMToken';

const useFCM = () => {
  const { fcmToken, addFMCToken, removeFMCToken } = useFCMToken();
  const [messages, setMessages] = useState<MessagePayload[]>([]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const messaging = getMessaging(getFirebaseApp());
      const unsubscribe = onMessage(messaging, (payload) => {
        setMessages((val) => [...val, payload]);
      });
      return () => unsubscribe();
    }
  }, [fcmToken]);
  return { fcmToken, messages, addFMCToken, removeFMCToken };
};

export default useFCM;
