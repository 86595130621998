import React from 'react';

export default function WorkspaceSkeleton() {
  return (
    <div className="flex w-full items-center gap-2">
      <div className="h-[32px] w-[32px] animate-pulse rounded-full bg-dark-200 px-2 dark:bg-dark-500"></div>
      <div className="flex h-[48px] w-full items-center px-2">
        <div className="h-[32px] w-full animate-pulse rounded bg-dark-200 dark:bg-dark-500"></div>
      </div>
    </div>
  );
}
