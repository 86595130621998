'use client';

import { useEffect, useState } from 'react';

import { MemberRole } from '@/shared/config/members-roles.config';
import { useGetWorkspaceMembers } from '@/shared/firebase/client/hooks';
import { useActiveWorkspace } from '@/shared/hooks/useActiveWorkspace';
import { useAuth } from '@/shared/hooks/useAuth';
import { WorkspaceMemberContext } from '@/stores/WorkspaceMemberContext';

type WorkspaceMemberProviderProps = {
  children: React.ReactNode;
};

export default function WorkspaceMemberProvider({
  children,
}: WorkspaceMemberProviderProps) {
  const [userRole, setUserRole] = useState<MemberRole>(MemberRole.Guest);
  const [isOwner, setIsOwner] = useState(false);
  const { user: authUser } = useAuth();
  const { activeWorkspace, state: activeWorkspaceState } = useActiveWorkspace();

  const { data: workspaceMember, state: workspaceMemberState } =
    useGetWorkspaceMembers({
      userId: authUser?.uid,
      workspaceId: activeWorkspace?.id,
      key: 'workspace-member-provider',
    });

  useEffect(() => {
    if (
      (workspaceMemberState.isSuccess || workspaceMemberState.isFetched) &&
      activeWorkspace
    ) {
      if (authUser?.uid === activeWorkspace.user.id) {
        setUserRole(MemberRole.Admin);
        setIsOwner(true);
      } else {
        setIsOwner(false);
        if (workspaceMember[0]) {
          setUserRole(workspaceMember[0].role);
        } else {
          setUserRole(MemberRole.Guest);
        }
      }
    }
  }, [
    workspaceMemberState.isSuccess,
    workspaceMemberState.isRefetching,
    activeWorkspaceState?.isSuccess,
    activeWorkspaceState?.isRefetching,
  ]);

  return (
    <WorkspaceMemberContext.Provider
      value={{
        workspaceMember: workspaceMember[0] ?? null,
        isOwner,
        role: userRole,
        state: {
          ...workspaceMemberState,
          isLoading:
            (activeWorkspaceState?.isLoading ||
              workspaceMemberState?.isLoading) ??
            false,
          isSuccess:
            (activeWorkspaceState?.isSuccess ||
              workspaceMemberState?.isSuccess) ??
            false,
          isError:
            (activeWorkspaceState?.isError || workspaceMemberState?.isError) ??
            false,
        },
      }}
    >
      {children}
    </WorkspaceMemberContext.Provider>
  );
}
