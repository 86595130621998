import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

import TextInput from '@/common/component/forms/TextInput';

export default function SearchInput() {
  const [search, setSearch] = useState('');

  return (
    <TextInput
      value={search}
      type="text"
      onChange={(e) => setSearch(e.currentTarget.value)}
      autoComplete=""
      fullWidth
      size="small"
      inputAdornment={{
        startAdornment: <MagnifyingGlassIcon className="w-5" />,
      }}
      placeholder="Search videos, folders and friends"
    />
  );
}
