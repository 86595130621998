import { Button, Tooltip } from '@canvaza/serval-ui';
import {
  ArrowDownTrayIcon,
  ExclamationCircleIcon,
  VideoCameraIcon,
  VideoCameraSlashIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import UpgradeMessageCard from '@/app/(protected)/(user)/workspace/UpgradeMessageCard';
import useAttributes from '@/shared/hooks/useAttributes';
import { useRecorder } from '@/shared/hooks/useRecorder';
import { MutedMicIcon, UnMutedMicIcon } from '@/shared/utils/svg';

import SelectInput from '../../forms/SelectInput';
import type {
  DeviceStatus,
  RecorderSettings,
  VideoFileFormatType,
  VideoSettingType,
} from '..';
import type {
  MediaRecorderRefs,
  MediaResourceOption,
  MediaResources,
} from '../media-recorder';
import Header from './Header';
import SettingModal from './setting-modal';

type RecorderSettingProps = {
  recorderSettings: RecorderSettings;
  showRecorderSetting: boolean;
  deviceStatus: DeviceStatus;
  refs: MediaRecorderRefs;
  onMobileDevice?: boolean;
  mediaResources: MediaResources;
  onRecorderSettingChange: React.Dispatch<
    React.SetStateAction<RecorderSettings>
  >;
  onDeviceStatusChange: React.Dispatch<React.SetStateAction<DeviceStatus>>;
  onCloseRecorderSetting: () => void;
};
export default function RecorderSetting({
  refs,
  recorderSettings,
  deviceStatus,
  showRecorderSetting,
  mediaResources,
  onMobileDevice,
  onDeviceStatusChange,
  onRecorderSettingChange,
  onCloseRecorderSetting,
}: RecorderSettingProps) {
  const [startButtonLabel, setStartButtonLabel] = useState('Start recording');
  const { attributes, canAddVideo } = useAttributes();
  const { recorder, onRecorderChanged } = useRecorder();

  const { audioMuted, camera } = deviceStatus;
  const { recorderRef, recorderContainerRef } = refs;

  const { permissionState } = recorder;

  useEffect(() => {
    if (
      attributes?.video.duration &&
      typeof attributes?.video.duration === 'number'
    ) {
      setStartButtonLabel(
        `Record for ${attributes.video.duration} minutes, starting now.`
      );
    }

    if (
      attributes?.video.duration &&
      typeof attributes?.video.duration === 'string' &&
      attributes?.video.duration === 'inf'
    ) {
      setStartButtonLabel(`Start recording without a time limit`);
    }
  }, [attributes?.video.duration]);

  const getMicAvailableOption = (): MediaResourceOption<string>[] => {
    if (mediaResources.audioInputs.length === 0) {
      return [
        {
          label: 'Mic is not available',
          value: '',
        },
      ];
    }

    if (permissionState?.mic === 'prompt') {
      return [
        {
          label: 'Mic is turned off',
          value: '',
        },
      ];
    }

    if (permissionState?.mic === 'denied') {
      return [
        {
          label: 'Mic is blocked',
          value: '',
        },
      ];
    }
    return mediaResources.audioInputs;
  };

  const getCameraAvailableOption = (): MediaResourceOption<string>[] => {
    if (mediaResources.videoInputs.length === 0) {
      return [
        {
          label: 'Camera is not available',
          value: '',
        },
      ];
    }

    if (permissionState?.camera === 'prompt') {
      return [
        {
          label: 'Camera is turned off',
          value: '',
        },
      ];
    }

    if (permissionState?.camera === 'denied') {
      return [
        {
          label: 'Camera is blocked',
          value: '',
        },
      ];
    }
    return mediaResources.videoInputs;
  };

  const handleVideoSettingChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement;
    if (target && target.value)
      onRecorderSettingChange((prev) => ({
        ...prev,
        videoSetting: target.value as VideoSettingType,
      }));
  };

  const handleVideoSourceChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement;
    if (target && target.value)
      if (e.currentTarget?.value)
        onRecorderSettingChange((prev) => ({
          ...prev,
          videoSourceId: target.value,
        }));
  };

  const handleAudioSourceChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement;
    if (target && target.value)
      onRecorderSettingChange((prev) => ({
        ...prev,
        audioSourceId: target.value,
      }));
  };

  const handleFileFormat = (e: React.FormEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement;
    if (target && target.value)
      onRecorderSettingChange((prev) => ({
        ...prev,
        outPutFileFormat: target.value as VideoFileFormatType,
      }));
  };

  const handleToggleMuteAudio = () => {
    if (audioMuted) {
      onDeviceStatusChange((prev) => ({ ...prev, audioMuted: false }));
    } else {
      onDeviceStatusChange((prev) => ({ ...prev, audioMuted: true }));
    }
  };

  const handleToggleCamera = () => {
    if (camera) {
      onDeviceStatusChange((prev) => ({ ...prev, camera: false }));
    } else {
      onDeviceStatusChange((prev) => ({ ...prev, camera: true }));
    }
  };

  const handleEnableDisableMic = () => {
    if (
      mediaResources.audioInputs.length === 0 &&
      permissionState?.mic === 'granted'
    ) {
      onRecorderChanged((prev) => ({
        ...prev,
        noDeviceInputs: {
          ...prev.noDeviceInputs,
          mic: true,
        },
      }));
    }
    if (permissionState?.mic !== 'granted') {
      onRecorderChanged((prev) => ({
        ...prev,
        permissionStateModal: {
          ...prev.permissionStateModal,
          mic: true,
        },
      }));
    }
    handleToggleMuteAudio();
  };

  const handleEnableDisableCamera = () => {
    if (
      mediaResources.videoInputs.length === 0 &&
      permissionState?.camera === 'granted'
    ) {
      onRecorderChanged((prev) => ({
        ...prev,
        noDeviceInputs: {
          ...prev.noDeviceInputs,
          camera: true,
        },
      }));
    }
    if (permissionState?.camera !== 'granted') {
      onRecorderChanged((prev) => ({
        ...prev,
        permissionStateModal: {
          ...prev.permissionStateModal,
          camera: true,
        },
      }));

      if (permissionState?.camera === 'prompt') {
        recorderRef.current?.onManuallyEnableCamera(true);
      }
      return;
    }
    handleToggleCamera();
  };

  const handleStartRecording = () => {
    if (
      (permissionState.camera === 'denied' ||
        permissionState.camera === 'prompt') &&
      (permissionState.mic === 'denied' || permissionState.mic === 'prompt')
    ) {
      onRecorderChanged((prev) => ({
        ...prev,
        permissionStateModal: { ...prev.permissionStateModal, both: true },
      }));
    } else {
      recorderRef.current?.onStartRecording();
    }
  };
  return (
    <SettingModal
      open={showRecorderSetting}
      onHide={onCloseRecorderSetting}
      excludeRef={recorderContainerRef}
    >
      {onMobileDevice ? (
        <div className="flex w-full flex-col items-start justify-start gap-8 p-4">
          <div className="flex flex-col gap-2">
            <p className="text-sm font-light">
              {'Video Recording Feature is not available on mobile.'}
            </p>
            <p className="text-sm font-semibold">
              {
                "Please consider downloading the app to access Canvaza's full feature, or use Canvaza on desktop or laptop "
              }
            </p>
          </div>
          <Button
            variant="contained"
            startIcon={<ArrowDownTrayIcon className="w-5 stroke-dark-light" />}
          >
            <span> Download Mobile App</span>
          </Button>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-3 p-4">
          <Header />
          {!canAddVideo && (
            <UpgradeMessageCard
              description={`Reach your full video potential: While current plan allows for ${attributes?.video.limit} videos, 
          consider upgrading to unlock unlimited storage and explore all the features.`}
            />
          )}

          <SelectInput
            value={recorderSettings.videoSetting}
            onChange={(e) => handleVideoSettingChange(e)}
            disabled={!canAddVideo}
            options={recorderRef.current?.videoSettingOption.settings ?? []}
            label="Video Settings"
            required
            fullWidth
          />

          <SelectInput
            disabled={!canAddVideo}
            value={recorderSettings.outPutFileFormat}
            onChange={handleFileFormat}
            label="Video output format"
            options={recorderRef.current?.videoSettingOption.format ?? []}
            fullWidth
          />

          <div className="flex w-full flex-col justify-end text-dark dark:text-dark-light">
            <span>Microphone source</span>
            <div className="flex w-full items-center justify-center gap-0 py-2">
              <div className="relative">
                <button
                  disabled={!canAddVideo}
                  className={clsx(
                    'rounded-lg rounded-r-none bg-dark-200 p-[0.56rem] dark:bg-dark-600',
                    'transition-all duration-500 ease-in-out',
                    {
                      'bg-error dark:bg-error-light hover:bg-error-dark dark:hover:bg-error-dark':
                        audioMuted &&
                        permissionState.mic === 'granted' &&
                        canAddVideo,
                      'bg-error/50 dark:bg-error-light/50 hover:':
                        audioMuted &&
                        permissionState.mic === 'granted' &&
                        !canAddVideo,
                      'fill-dark/5': !canAddVideo,
                    }
                  )}
                  onClick={handleEnableDisableMic}
                >
                  {audioMuted ? (
                    <MutedMicIcon
                      className={clsx('w-6 fill-dark dark:fill-dark-light', {
                        'fill-dark/25 dark:fill-dark-light/25': !canAddVideo,
                      })}
                    />
                  ) : (
                    <UnMutedMicIcon
                      className={clsx('w-6 fill-dark dark:fill-dark-light', {
                        'fill-dark/25 dark:fill-dark-light/25': !canAddVideo,
                      })}
                    />
                  )}
                </button>
                {(permissionState?.mic !== 'granted' ||
                  mediaResources.audioInputs.length === 0) && (
                  <div
                    onClick={handleEnableDisableMic}
                    className="absolute -right-2 -top-4 cursor-pointer"
                  >
                    <Tooltip placement="top" content="Show information">
                      <ExclamationCircleIcon className="w-7 fill-warning stroke-dark-lighter stroke-2" />
                    </Tooltip>
                  </div>
                )}
              </div>

              <SelectInput
                className="rounded-l-none"
                value={recorderSettings.audioSourceId}
                onChange={handleAudioSourceChange}
                disabled={
                  audioMuted ||
                  mediaResources.audioInputs.length === 0 ||
                  !canAddVideo
                }
                options={getMicAvailableOption()}
                fullWidth
              />
            </div>
          </div>

          <div className="flex w-full flex-col justify-end text-dark dark:text-dark-light">
            <span>Camera source</span>
            <div className="flex w-full items-center justify-center gap-0 py-2">
              <div className="relative">
                <button
                  disabled={!canAddVideo}
                  className={clsx(
                    'rounded-lg rounded-r-none bg-dark-200 p-[0.56rem] dark:bg-dark-600',
                    'transition-all duration-500 ease-in-out',
                    {
                      'bg-error dark:bg-error-light hover:bg-error-dark dark:hover:bg-error-dark':
                        !camera &&
                        permissionState?.camera === 'granted' &&
                        canAddVideo,
                      'bg-error/50 dark:bg-error-light/50 hover:':
                        !camera &&
                        permissionState?.camera === 'granted' &&
                        !canAddVideo,
                      'text-dark/25': !canAddVideo,
                    }
                  )}
                  onClick={handleEnableDisableCamera}
                >
                  {camera ? (
                    <VideoCameraIcon className="w-6" title="Camera off" />
                  ) : (
                    <VideoCameraSlashIcon
                      className={clsx(
                        'w-6 stroke-dark stroke-2 dark:stroke-dark-light'
                      )}
                      title="Camera on"
                    />
                  )}
                </button>
                {permissionState?.camera !== 'granted' && (
                  <div
                    onClick={handleEnableDisableCamera}
                    className="absolute -right-2 -top-4 cursor-pointer"
                  >
                    <Tooltip placement="top" content="Show information">
                      <ExclamationCircleIcon className="w-7 fill-warning stroke-dark-lighter stroke-2" />
                    </Tooltip>
                  </div>
                )}
              </div>

              <SelectInput
                className="rounded-l-none"
                value={recorderSettings.videoSourceId}
                onChange={handleVideoSourceChange}
                disabled={
                  !camera ||
                  mediaResources.videoInputs.length === 0 ||
                  !canAddVideo
                }
                options={getCameraAvailableOption()}
                fullWidth
              />
            </div>
          </div>

          <div className="flex w-full">
            <Button
              disabled={!canAddVideo}
              onClick={handleStartRecording}
              variant="contained"
              color="error"
              size="large-x"
              fullWidth
            >
              <span className="flex items-center gap-1">
                {startButtonLabel}
              </span>
            </Button>
          </div>
        </div>
      )}
    </SettingModal>
  );
}
