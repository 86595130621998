import clsx from 'clsx';
import React from 'react';

import LeftSideContent from './left-side-contents';
import RightSideButtons from './right-side-buttons';

type AppBarProps = { isLargeScreen: boolean; onSidebarOpen: () => void };
export default function AppBar({ isLargeScreen, onSidebarOpen }: AppBarProps) {
  return (
    <div
      className={clsx(
        'sticky top-0 z-20 flex h-full w-full items-center justify-between gap-2 !rounded-b-none  bg-dark-lighter px-3 dark:bg-dark-600'
      )}
    >
      <LeftSideContent
        isLargeScreen={isLargeScreen}
        onSidebarOpen={onSidebarOpen}
      />
      <RightSideButtons />
    </div>
  );
}
