/* eslint-disable react/no-unescaped-entities */
import { Button, Modal } from '@canvaza/serval-ui';
import React from 'react';

import { useRecorder } from '@/shared/hooks/useRecorder';

type BothDeniedOrPromptInfoModalProps = { onStartRecording?: () => void };
export default function BothDeniedOrPromptInfoModal({
  onStartRecording,
}: BothDeniedOrPromptInfoModalProps) {
  const { recorder, onRecorderChanged } = useRecorder();
  const { permissionState, permissionStateModal } = recorder;
  const closeBothPromptOrDeniedInfoModal = () => {
    onRecorderChanged((prev) => ({
      ...prev,
      permissionStateModal: { ...prev.permissionStateModal, both: false },
    }));
  };

  const handleStartRecording = () => {
    if (!onStartRecording) return;

    onRecorderChanged((prev) => ({
      ...prev,
      permissionStateModal: { ...prev.permissionStateModal, both: false },
    }));
    onStartRecording();
  };

  return (
    <Modal
      open={
        (permissionState.camera === 'denied' ||
          permissionState.camera === 'prompt') &&
        (permissionState.mic === 'denied' ||
          permissionState.mic === 'prompt') &&
        permissionStateModal.both
      }
      title="Your browser is blocking media devices"
      size="small"
      actions={<></>}
      onClose={closeBothPromptOrDeniedInfoModal}
    >
      <div className="flex w-full items-center justify-center gap-6 p-4">
        <div className="flex w-full flex-col gap-6">
          <p>
            Ready for camera and mic action? Click "allow" on both, then reload
            the page - it's showtime!
          </p>

          <div className="flex w-full flex-col items-center justify-center gap-2">
            <span className="font-semibold">
              Do you wish to proceed with screen only record?
            </span>
            <div className="flex w-full items-center justify-center gap-3">
              <div>
                <Button size="small" onClick={closeBothPromptOrDeniedInfoModal}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  disabled={!onStartRecording}
                  onClick={handleStartRecording}
                  size="small"
                  variant="contained"
                >
                  Yes, proceed with screen only
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
