'use client';

import { Modal } from '@canvaza/serval-ui';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';

import type { LayoutProps } from '@/app/types';
import { isAuthPath, isPublicPath } from '@/middleware/paths';
import {
  CHECK_VERIFY_EMAIL,
  FEEDBACK,
  ONBOARD,
  UPLOAD,
} from '@/shared/config/route-links.config';
import { workspaceNavigation } from '@/shared/config/sidebar-navigation.config';
import { useActiveWorkspace } from '@/shared/hooks/useActiveWorkspace';
import useAdminAbility from '@/shared/hooks/useAdminAbility';
import { useAuth } from '@/shared/hooks/useAuth';
import { useRecorder } from '@/shared/hooks/useRecorder';
import { GradientLoading } from '@/shared/utils/svg';

import PageHeader from '../../page-header';
import Recorder from '../../recorder';
import LayoutContainer from './LayoutContainer';

export default function WorkspaceLayout({ children }: LayoutProps) {
  const { user: authUser } = useAuth();
  const ability = useAdminAbility();
  const pathname = usePathname();
  const { activeWorkspace } = useActiveWorkspace();

  const router = useRouter();
  const { recorder, onRecorderChanged } = useRecorder();

  useEffect(() => {
    if (recorder.file && !recorder.isCancelled) {
      onRecorderChanged((prev) => ({
        ...prev,
        isRouting: true,
      }));
      router.push(UPLOAD.url);
    }

    if (recorder.isCancelled) {
      onRecorderChanged((prev) => ({
        ...prev,
        isCancelled: false,
        file: null,
        url: null,
      }));
    }
  }, [recorder.file]);

  const navigation = useMemo(
    () => workspaceNavigation(authUser, activeWorkspace),
    [ability, authUser, activeWorkspace]
  );

  const WITHOUT_LAYOUT_PATHS = [
    CHECK_VERIFY_EMAIL.url,
    FEEDBACK.url,
    ONBOARD.url,
  ];

  const isWithoutLayoutPath = () => {
    return WITHOUT_LAYOUT_PATHS.some((path) => pathname.startsWith(path));
  };

  if (isAuthPath(pathname) || isPublicPath(pathname) || isWithoutLayoutPath()) {
    return <>{children}</>;
  }

  return (
    <LayoutContainer layout="workspace" navigation={navigation}>
      <PageHeader />
      {children}
      <Recorder />
      <Modal open={recorder.isRouting || false} size="small-x" actions={<></>}>
        <div className="flex flex-col items-center justify-center gap-4 px-2 pb-2">
          <p className="text-xl font-semibold">
            Getting a video ready to upload...
          </p>

          <GradientLoading className="w-14 animate-spin" />
        </div>
      </Modal>
    </LayoutContainer>
  );
}
