import type { FieldValue, Timestamp } from 'firebase/firestore';
import moment from 'moment';

export const toHHMMSS = (second: number): string => {
  const secondsNum = Math.ceil(second);

  const hours = Math.floor(secondsNum / (60 * 60));

  const divisorForMinutes = secondsNum % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const secondCalc = Math.ceil(divisorForSeconds);

  let time = '';

  if (hours > 0) {
    time += `${hours}:`;
  }

  if (minutes < 10 && hours > 0) {
    time += '0';
  }
  time += `${minutes}:`;

  if (secondCalc < 10) {
    time += '0';
  }
  time += secondCalc;

  return time;
};

export const microSecondsToMinute = (microSeconds: number) => {
  return (microSeconds / 60).toFixed(2);
};

export function convertFirebaseTimestampToDate(timestamp: FieldValue) {
  const { seconds, nanoseconds } = timestamp as Timestamp;

  const date = new Date(seconds * 1000);

  return moment(date)
    .add(nanoseconds / 1e9)
    .format('YYYY-MM-DD HH:mm:ss');
}
