'use client';

import { useState } from 'react';

import type { RecorderType } from '@/stores/recorderContext';
import { RecordContext } from '@/stores/recorderContext';

type RecordProviderProps = {
  children: React.ReactNode;
};

export default function RecorderProvider({ children }: RecordProviderProps) {
  const [recorder, setRecorder] = useState<RecorderType>({
    file: null,
    url: null,
    isCancelled: false,
    isRouting: false,
    show: false,
    noDeviceInputs: {
      camera: false,
      mic: false,
    },
    permissionState: {
      camera: 'prompt',
      mic: 'prompt',
    },
    permissionStateModal: {
      camera: false,
      mic: false,
      both: false,
    },
  });
  return (
    <div>
      <RecordContext.Provider
        value={{
          recorder,
          onRecorderChanged: setRecorder,
        }}
      >
        {children}
      </RecordContext.Provider>
    </div>
  );
}
