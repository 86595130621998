import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { useRef } from 'react';

import useCustomOutsideClick from './hooks/useCustomOutsideClick';

type SettingModalProps = {
  open: boolean;
  excludeRef: any;
  children: React.ReactNode;
  onHide: () => void;
};
export default function SettingModal({
  open,
  excludeRef,
  children,
  onHide,
}: SettingModalProps) {
  const modalContainer = useRef() as React.MutableRefObject<HTMLInputElement>;
  const outsideClickHandler = React.useCallback(() => {
    if (onHide) return onHide();
    return () => {};
  }, [onHide]);

  useCustomOutsideClick(modalContainer, outsideClickHandler, excludeRef);

  return (
    <div className="max-h-max max-w-max">
      <Transition.Root
        as={React.Fragment}
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={open}
      >
        <div
          className="fixed inset-0 z-30 bg-dark/50 dark:bg-dark-600/50"
          id="modal"
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex h-full items-center justify-center">
              <div
                ref={modalContainer}
                role="alert"
                className={clsx(
                  'container absolute right-6 top-10 w-11/12 sm:w-96'
                )}
              >
                <div className="rounded-md border border-dark-lighter bg-dark-lighter p-2 shadow-md  dark:border-dark-darker dark:bg-dark-darker">
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </div>
  );
}
