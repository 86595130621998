export const FEATURES = {
  Attributes: {
    video: {
      quality: false,
    },
    integration: false,
    allowCustomization: false,
    notificationSettingsClearByDate: false,
    downloadPlatformOptions: false,
  },
};
