import { Collection } from '../../constants/collection';
import type { IItem } from '../../models';
import { getByIdQuery } from '../helper/firebase-function-query';
import type { IGetByIdQueryResult } from '../types';
import { getDocId } from '../utils/doc-id';

export const getItemUid = () => getDocId(Collection.ITEMS);

export const getItemById = async (
  id: string
): Promise<IGetByIdQueryResult<IItem>> => {
  try {
    return await getByIdQuery<IItem>(Collection.ITEMS, id);
  } catch (error: any) {
    throw new Error(`Error: Getting item. ${error.message}`);
  }
};
