/* eslint-disable import/no-cycle */
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import type { StatusMessages } from 'react-media-recorder-2';
import { useMedia } from 'react-use';

import type { MediaResources } from '@/common/component/recorder/media-recorder';
import { useRecorder } from '@/shared/hooks/useRecorder';

import type { DeviceStatus, RecorderRefType, RecorderSettings } from '..';
import DeviceAccessStatusModals from '../device-access-status-modals';
import RecorderSetting from '../recorder-settings';

const MediaRecorder = dynamic(
  () => import('@/common/component/recorder/media-recorder'),
  {
    ssr: false,
  }
);
export default function CanvazaMediaRecorder() {
  const mobileDevice = useMedia('(min-width: 768px)', false);
  const [mediaResources, setMediaResources] = useState<MediaResources>({
    audioInputs: [],
    videoInputs: [],
  });
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>({
    camera: true,
    audioMuted: false,
  });

  const [recorderSettings, setRecorderSettings] = useState<RecorderSettings>({
    videoSourceId: 'default',
    videoSetting: 'camera_and_screen',
    outPutFileFormat: 'mp4',
  });
  const [recorderStatus, setRecorderStatus] = useState<StatusMessages>('idle');

  const recorderRef = React.useRef<RecorderRefType>();
  const recorderContainerRef = React.useRef<any>();

  const { recorder, onRecorderChanged } = useRecorder();
  const { permissionStateModal, noDeviceInputs } = recorder;

  const handleRecorderStateChanged = (show: boolean) => {
    if (
      permissionStateModal?.camera ||
      permissionStateModal?.mic ||
      permissionStateModal?.both ||
      noDeviceInputs?.camera ||
      noDeviceInputs?.mic
    )
      return;
    onRecorderChanged((prev) => ({ ...prev, show }));
  };

  return (
    <>
      {((mobileDevice && recorder.show) ||
        recorderStatus === 'paused' ||
        recorderStatus === 'recording') && (
        <MediaRecorder
          recorderSettings={recorderSettings}
          refs={{ recorderRef, recorderContainerRef }}
          deviceStatus={deviceStatus}
          mediaResources={mediaResources}
          onDeviceStatusChange={setDeviceStatus}
          onCloseRecorder={() => handleRecorderStateChanged(false)}
          onRecorderSettingChange={setRecorderSettings}
          onSetRecorderStatus={setRecorderStatus}
          onLoadResources={setMediaResources}
        />
      )}
      <RecorderSetting
        onMobileDevice={!mobileDevice}
        deviceStatus={deviceStatus}
        recorderSettings={recorderSettings}
        showRecorderSetting={
          (recorder.show && recorderStatus === 'idle') ||
          recorderStatus === 'acquiring_media'
        }
        refs={{ recorderRef, recorderContainerRef }}
        mediaResources={mediaResources}
        onRecorderSettingChange={setRecorderSettings}
        onDeviceStatusChange={setDeviceStatus}
        onCloseRecorderSetting={() => handleRecorderStateChanged(false)}
      />
      {mobileDevice && (
        <DeviceAccessStatusModals
          onStartRecording={recorderRef.current?.onStartRecording}
        />
      )}
    </>
  );
}
