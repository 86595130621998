import type { WithGroupedProps } from '@/common/component/layout/types';
import {
  ANALYTICS,
  GENERAL_SETTINGS,
  HISTORY,
  HOME,
  LIBRARY,
  MEMBERS,
  NOTIFICATION_SETTING,
  PAYMENT_METHOD_SETTING,
  PERSONAL_SETTING,
  PLANS,
  REQUESTS,
  ROLES,
  SECURITY_SETTING,
  STARRED,
  SUBSCRIPTION_SETTING,
  TRASH,
  USERS,
  WORKSPACE_SETTING,
} from '@/shared/config/route-links.config';
import type { User } from '@/stores/authContext';

import type { AppAbilityType } from '../casl/ability';
import type { IWorkspace } from '../firebase';

export const adminNavigation = (ability: AppAbilityType) =>
  [
    {
      groupName: '',
      items: [
        {
          ...GENERAL_SETTINGS,
          hasPermission: ability.can('view', 'general_settings'),
        },

        {
          ...ROLES,
          hasPermission: ability.can('view', 'roles'),
        },

        {
          ...USERS,
          hasPermission: ability.can('view', 'users'),
        },

        {
          ...PLANS,
          hasPermission: ability.can('view', 'plans'),
        },
        {
          ...REQUESTS,
          hasPermission: ability.can('view', 'contacts'),
        },
      ],
    },
  ].filter(Boolean) as unknown as WithGroupedProps[];

export const workspaceNavigation = (
  authUser: User | null,
  workspace: IWorkspace | null
) =>
  [
    {
      groupName: '',
      items: [
        {
          ...HOME,
          hasPermission: authUser !== null,
          onMobileNav: true,
        },
        {
          ...LIBRARY,
          hasPermission: authUser !== null,
          onMobileNav: true,
        },
        {
          ...HISTORY,
          hasPermission: authUser !== null,
          onMobileNav: false,
        },
        {
          ...ANALYTICS,
          hasPermission: authUser !== null,
          onMobileNav: false,
        },

        {
          ...STARRED,
          hasPermission: authUser !== null,
          onMobileNav: false,
        },
        {
          ...MEMBERS,
          hasPermission: authUser !== null && workspace && !workspace.personal,
          onMobileNav: true,
        },
        {
          ...TRASH,
          hasPermission: authUser !== null,
          onMobileNav: true,
        },
      ],
    },
  ].filter(Boolean) as unknown as WithGroupedProps[];

export const settingNavigation = (authUser: User | null) =>
  [
    {
      groupName: '',
      items: [
        {
          ...PERSONAL_SETTING,
          hasPermission: authUser !== null,
        },

        {
          ...NOTIFICATION_SETTING,
          hasPermission: authUser !== null,
        },

        {
          ...SECURITY_SETTING,
          hasPermission:
            authUser && authUser?.customClaims.signInProvider === 'password',
        },

        {
          ...WORKSPACE_SETTING,
          hasPermission: authUser !== null,
        },

        {
          ...PAYMENT_METHOD_SETTING,
          hasPermission: authUser !== null,
        },

        {
          ...SUBSCRIPTION_SETTING,
          hasPermission: authUser !== null,
        },
      ],
    },
  ].filter(Boolean) as unknown as WithGroupedProps[];
