'use client';

import { useEffect, useState } from 'react';

import {
  useGetWorkspaceMembers,
  useGetWorkspaces,
} from '@/shared/firebase/client/hooks';
import type { IWorkspace, IWorkspaceMember } from '@/shared/firebase/models';
import { useAuth } from '@/shared/hooks/useAuth';
import { UserWorkspacesContext } from '@/stores/userWorkspacesContext';

type WorkspaceType = 'workspace' | 'workspaceMember';
export type IUserWorkspaceList = {
  workspace: IWorkspace | IWorkspaceMember;
  type: WorkspaceType;
};
type UserWorkspacesProviderProps = {
  children: React.ReactNode;
};

export default function UserWorkspacesProvider({
  children,
}: UserWorkspacesProviderProps) {
  const [workspaceList, setWorkspaceList] = useState<IUserWorkspaceList[]>([]);

  const { user: authUser } = useAuth();

  const { data: userWorkspaces, state: userWorkspacesState } = useGetWorkspaces(
    { userId: authUser?.uid, key: 'user-workspace-provider' }
  );

  const { data: userMemberWorkspaces, state: userMemberWorkspacesState } =
    useGetWorkspaceMembers({
      userId: authUser?.uid,
      key: 'current-user-member',
    });

  useEffect(() => {
    const newUserWorkspaces: IUserWorkspaceList[] = [];
    const newUserMemberedWorkspaces: IUserWorkspaceList[] = [];
    if (
      userMemberWorkspacesState.isSuccess ||
      userWorkspacesState.isSuccess ||
      userWorkspacesState.isFetched ||
      userMemberWorkspacesState.isFetched
    )
      setWorkspaceList([]);

    if (
      userWorkspacesState.isSuccess &&
      userWorkspacesState.isFetched &&
      userWorkspaces.length > 0
    ) {
      newUserWorkspaces.push(
        ...userWorkspaces.map((workspace) => ({
          workspace,
          type: 'workspace' as WorkspaceType,
        }))
      );
    }

    if (
      userMemberWorkspacesState.isSuccess &&
      userMemberWorkspacesState.isFetched &&
      userMemberWorkspaces.length > 0
    ) {
      newUserMemberedWorkspaces.push(
        ...userMemberWorkspaces.map((userMemberWorkspace) => ({
          workspace: userMemberWorkspace,
          type: 'workspaceMember' as WorkspaceType,
        }))
      );
    }
    setWorkspaceList([...newUserWorkspaces, ...newUserMemberedWorkspaces]);
  }, [
    userWorkspacesState.isSuccess,
    userMemberWorkspacesState.isSuccess,
    userWorkspacesState.dataUpdatedAt,
    userMemberWorkspacesState.dataUpdatedAt,
  ]);

  const refetch = () => {
    userWorkspacesState.refetch();
    userMemberWorkspacesState.refetch();
  };

  return (
    <UserWorkspacesContext.Provider
      value={{
        workspaceList,
        state: {
          isLoading:
            userWorkspacesState.isLoading ||
            userMemberWorkspacesState.isLoading,
          isFetching:
            userWorkspacesState.isFetching ||
            userMemberWorkspacesState.isFetching,
          isSuccess:
            userWorkspacesState.isSuccess ||
            userMemberWorkspacesState.isSuccess,
          isError:
            userWorkspacesState.isError || userMemberWorkspacesState.isError,
          error: userWorkspacesState.error || userMemberWorkspacesState.error,
          isRefetching:
            userWorkspacesState.isRefetching ||
            userMemberWorkspacesState.isRefetching,
          isFetched:
            userWorkspacesState.isFetched ||
            userMemberWorkspacesState.isFetched,
          refetch,
        },
      }}
    >
      {children}
    </UserWorkspacesContext.Provider>
  );
}
