import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/index.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/common/component/layout/internal/AppLayout.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/ActiveWorkspaceProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/AdminAbilityProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/AppProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/AuthProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/CurrentUserProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/RecorderProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/RQAppProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/UserAbilityProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/UserWorkspacesProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/canvaza-monorepo/canvaza-monorepo/frontend/src/providers/WorkspaceMemberProvider.tsx")