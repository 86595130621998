'use client';

import { Button } from '@canvaza/serval-ui';
import {
  ArrowUpTrayIcon,
  UserPlusIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useParams, usePathname } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import ItemUploadRender from '@/app/(protected)/(user)/library/[collectionId]/item/ItemUploadRender';
import InviteFriendsRender from '@/app/(protected)/(user)/members/components/InviteFriendsRender';
import BreadCrump from '@/common/widgets/bread-crump';
import {
  ANALYTICS,
  HISTORY,
  HOME,
  ITEM,
  LIBRARY,
  MEMBERS,
  NOTIFICATIONS,
  RouterNavs,
  STARRED,
  UPGRADE,
  UPLOAD,
  WORKSPACE,
} from '@/shared/config/route-links.config';
import { useRecorder } from '@/shared/hooks/useRecorder';
import { flattenObject } from '@/shared/utils/object';

import LightBlueButton from '../button/LightBlueButton';

const PathNameWithRecord = [
  WORKSPACE.url,
  MEMBERS.url,
  HOME.url,
  LIBRARY.url,
  STARRED.url,
  NOTIFICATIONS.url,
  HISTORY.url,
];
const PathNameWithUpload = [STARRED.url, LIBRARY.url];
const PathNameWithInvitations = [
  WORKSPACE.url,
  MEMBERS.url,
  HOME.url,
  LIBRARY.url,
  STARRED.url,
];

const PathNameWithFilter = [ANALYTICS.url];

const PathWithNoHeader = [
  ITEM.url,
  UPGRADE.url,
  ...Object.values(flattenObject(RouterNavs.auth)),
];

export default function PageHeader() {
  const [collectionId, setCollectionId] = useState<string | null>(null);
  const pathName = usePathname();
  const params = useParams();

  const { onRecorderChanged } = useRecorder();

  const openRecorderModal = () => {
    onRecorderChanged((prev) => ({ ...prev, show: true }));
  };

  useEffect(() => {
    if (
      pathName?.startsWith(LIBRARY.url) &&
      params &&
      params &&
      params.collectionId !== undefined &&
      typeof params.collectionId === 'string'
    ) {
      setCollectionId(params.collectionId);
    }
  }, [pathName]);

  const anyActionToBeIncluded = useMemo(() => {
    const allPathNames = [
      ...PathNameWithRecord,
      ...PathNameWithFilter,
      ...PathNameWithInvitations,
      ...PathNameWithUpload,
    ];
    if (pathName && allPathNames.some((path) => pathName.startsWith(path))) {
      return true;
    }
    return false;
  }, [pathName]);

  const isInvitationIncluded = useMemo(() => {
    if (
      pathName &&
      PathNameWithInvitations.some((path) => pathName.startsWith(path))
    ) {
      return true;
    }
    return false;
  }, [pathName]);

  const isRecordIncluded = useMemo(() => {
    if (
      pathName &&
      PathNameWithRecord.some((path) => pathName.startsWith(path))
    ) {
      return true;
    }
    return false;
  }, [pathName]);

  const isUploadIncluded = useMemo(() => {
    if (
      pathName &&
      PathNameWithUpload.some((path) => pathName.startsWith(path))
    ) {
      return true;
    }
    return false;
  }, [pathName]);

  const isPageIncluded = useMemo(() => {
    if (
      pathName &&
      PathWithNoHeader.some((path) => pathName.startsWith(path))
    ) {
      return false;
    }
    return true;
  }, [pathName]);

  const isFilterIncluded = useMemo(() => {
    if (
      pathName &&
      PathNameWithFilter.some((path) => pathName.startsWith(path))
    ) {
      return true;
    }
    return false;
  }, [pathName]);

  return (
    <>
      {isPageIncluded ? (
        <div
          className={clsx('flex w-full flex-row gap-3', {
            'flex-col sm:flex-row justify-between': pathName.startsWith(
              UPLOAD.url
            ),
            'items-center justify-between': !pathName.startsWith(UPLOAD.url),
          })}
        >
          <BreadCrump />

          {anyActionToBeIncluded && (
            <div className="flex items-center">
              <div className="flex items-center gap-2 sm:gap-4">
                {isInvitationIncluded && (
                  <div>
                    <InviteFriendsRender>
                      {({ onOpenInvitationModal }) => (
                        <LightBlueButton
                          title="Invite friends"
                          icon={
                            <UserPlusIcon
                              className={clsx(
                                'w-4 stroke-dark-darker stroke-1 xs:h-5 xs:w-5 dark:stroke-dark-lighter'
                              )}
                            />
                          }
                          onClick={onOpenInvitationModal}
                          fullWidth
                        />
                      )}
                    </InviteFriendsRender>
                  </div>
                )}
                {isUploadIncluded && (
                  <div>
                    <ItemUploadRender collectionId={collectionId}>
                      {({ onOpenItemUploadModal }) => (
                        <LightBlueButton
                          icon={
                            <ArrowUpTrayIcon
                              className={clsx(
                                'w-4 stroke-[4px] text-dark-700 xs:w-5 xs:stroke-1 dark:text-dark-lighter'
                              )}
                            />
                          }
                          title="Upload video"
                          onClick={onOpenItemUploadModal}
                          fullWidth
                        />
                      )}
                    </ItemUploadRender>
                  </div>
                )}
                {isRecordIncluded && (
                  <Button
                    variant="contained"
                    className="bg-primary dark:bg-primary"
                    startIcon={
                      <VideoCameraIcon
                        className={clsx(
                          'h-4 w-4 stroke-2 xs:h-5 xs:w-5 xs:stroke-1 dark:stroke-dark-lighter'
                        )}
                      />
                    }
                    onClick={openRecorderModal}
                  >
                    <span className={clsx('hidden whitespace-nowrap md:flex')}>
                      New recording
                    </span>
                  </Button>
                )}

                {isFilterIncluded && <></>}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
