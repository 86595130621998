/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import { Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

import type {
  NavLinkNodeProps,
  SideNavigationItemProps,
  SideNavigationProps,
  WithChildNavLinkNodeProps,
  WithGroupedProps,
} from '../../../types';

export default function NavItemsList({
  onClose,
  isLargeScreen,
  navigation,
}: SideNavigationProps) {
  const [openedMenu, setOpenedMenu] = React.useState<Array<string>>([]);

  const pathname = usePathname();

  const menuItemsTransitionClasses = {
    enter: 'transition ease-out duration-200',
    enterFrom: 'opacity-0 -translate-y-1',
    enterTo: 'opacity-100 translate-y-0',
    leave: 'transition ease-in duration-150',
    leaveFrom: 'opacity-100 translate-y-0',
    leaveTo: 'opacity-0 -translate-y-1',
  };

  /**
   * Expand toggler
   * @param name
   */
  const handleExpand = (name: string) => {
    const menus = [...openedMenu];
    const index = menus.indexOf(name);
    if (index !== -1) {
      menus.splice(index, 1);
    } else {
      menus.push(name);
    }
    setOpenedMenu(menus);
  };

  /**
   * Check NavLink is active
   * @param name
   * @returns
   */
  const isSelectedMenu = (name: string) => {
    return openedMenu.indexOf(name) !== -1;
  };

  /**
   * Children reducer: to get child of children
   * @param items
   * @param children
   * @returns
   */
  function getChildren(
    items: SideNavigationItemProps[],
    children: SideNavigationItemProps[]
  ) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      children.push(item);
      if (item.children) {
        getChildren(item.children, children);
      }
    }
    return children;
  }

  /**
   * Child reducer
   * @param item
   * @returns
   */
  const someOpenedChildren = (item: SideNavigationItemProps) => {
    const children = getChildren(item.children, []);

    const index = children.findIndex((item) => {
      const itemLink = item.url?.length > 1 ? `${item.url}/` : item.url;
      return itemLink === pathname;
    });

    return index !== -1;
  };

  /**
   * Nav Link
   * @param param0
   * @returns
   */
  const NavLinkNode = ({
    item,
    marginLeft,
    flagComponent,
    onClose,
    isLargeScreen,
  }: NavLinkNodeProps) => {
    const activeClass = {
      'font-semibold bg-primary-lighter/30 dark:bg-primary/50 w-full py-2 border-primary':
        pathname?.startsWith(item.url),
    };
    const inActiveClass = {
      'py-2 border-transparent': !pathname?.startsWith(item.url),
    };
    return (
      <Link
        key={item.name}
        href={item.url}
        onClick={!isLargeScreen ? onClose : () => {}}
        style={{ marginLeft }}
        className={clsx(
          'border-l-4 hover:font-bold hover:text-primary dark:hover:text-primary-light',
          'group flex items-center gap-4 rounded-e-full font-medium',
          activeClass,
          inActiveClass
        )}
      >
        <div className="flex gap-2 px-3">
          {item.icon ? (
            <item.icon
              className={clsx('h-5 w-5', {
                'stroke-[3px]': pathname?.startsWith(item.url),
              })}
              aria-hidden="true"
            />
          ) : null}
          <span className="text-[0.9rem]">{item.name}</span>
          {flagComponent}
        </div>
      </Link>
    );
  };

  /**
   * Load Nav item with it's children
   * @param param0
   * @returns
   */
  const WithChildNavLinkNode = ({
    item,
    handleExpand,
    expanded,
    marginLeft,
    someOpenedChildren,
    isRoot,
  }: WithChildNavLinkNodeProps) => {
    return (
      <div
        key={item.name}
        onClick={() => handleExpand(item.name)}
        style={{ marginLeft }}
        className={clsx(
          {
            'text-primary': (expanded || someOpenedChildren) && isRoot,
            'text-primary font-bold': expanded || someOpenedChildren,
          },

          'cursor-pointer hover:bg-primary-lighter/30 hover:font-bold ',
          'group mr-2 flex items-center rounded-md text-base font-medium'
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            {item.icon ? (
              <item.icon
                className={clsx(
                  {
                    'fill-primary': pathname?.startsWith(item.url),
                  },
                  'h-5 w-5 shrink-0'
                )}
                aria-hidden="true"
              />
            ) : null}
            <span className="text-sm">{item.name}</span>
          </div>
          <div>
            {item.children ? (
              <ChevronRightIcon
                className={clsx(
                  { 'duration-200 rotate-90': expanded },
                  'h-4 w-4 shrink-0'
                )}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Load children navs recursively
   * @param children
   * @param marginLeft
   * @returns
   */
  function loadChildren(
    children: Array<SideNavigationItemProps>,
    marginLeft: number
  ): Array<JSX.Element> {
    return children
      .filter((item) => item.hasPermission)
      .map((item: SideNavigationItemProps, index: number) => {
        let loadedChildren;

        if (item.children) {
          loadedChildren = loadChildren(item.children, (marginLeft += 1));
        }

        return (
          <React.Fragment key={index}>
            {item.children ? (
              <WithChildNavLinkNode
                item={item}
                expanded={isSelectedMenu(item.name)}
                handleExpand={handleExpand}
                marginLeft={`${(item.children
                  ? (marginLeft -= 1)
                  : marginLeft
                ).toString()}rem`}
                index={index}
                someOpenedChildren={someOpenedChildren(item)}
                isRoot={false}
              />
            ) : (
              <NavLinkNode
                onClose={onClose}
                isLargeScreen={isLargeScreen}
                item={item}
                marginLeft={`${(item.children
                  ? (marginLeft -= 1)
                  : marginLeft
                ).toString()}rem`}
                index={index}
                isSelected={isSelectedMenu(item.name)}
                flagComponent={item.flagComponent}
              />
            )}

            <Transition
              show={loadedChildren && isSelectedMenu(item.name)}
              {...menuItemsTransitionClasses}
            >
              {loadedChildren}
            </Transition>
          </React.Fragment>
        );
      });
  }

  /**
   * Opened parents filter
   * @param items
   * @param parents
   * @returns
   */
  const getOnLoadOpenedParents = (
    items: SideNavigationItemProps[],
    parents: string[]
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      if (item.children) {
        const hasOpenedChild = someOpenedChildren(item);
        if (hasOpenedChild) {
          parents.push(item.name);
        }
        getOnLoadOpenedParents(item.children, parents);
      }
    }
    return parents;
  };

  React.useEffect(() => {
    // remove groupName
    const itemsOnly = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const group of navigation || []) {
      itemsOnly.push(...group.items);
    }
    setOpenedMenu([...openedMenu, ...getOnLoadOpenedParents(itemsOnly, [])]);
  }, []);

  return (
    <nav>
      {navigation?.map((group: WithGroupedProps, index: number) => {
        return (
          <div
            key={index}
            className={clsx('mt-2 flex flex-col', {
              'w-11/12': navigation.length - 1 > index,
            })}
          >
            <h1 className="text-xl font-medium text-dark-500 dark:text-dark-200">
              {group.groupName}
            </h1>
            <div className="flex h-full w-full flex-col gap-1">
              {group.items
                .filter((item) => item.hasPermission)
                .map((item, index) => {
                  let children;

                  if (item.children) {
                    children = loadChildren(item.children, 1);
                  }

                  return (
                    <React.Fragment key={index}>
                      {item.children ? (
                        <WithChildNavLinkNode
                          item={item}
                          expanded={isSelectedMenu(item.name)}
                          handleExpand={handleExpand}
                          marginLeft={'0rem'}
                          index={index}
                          someOpenedChildren={someOpenedChildren(item)}
                          isRoot={true}
                        />
                      ) : (
                        <NavLinkNode
                          index={index}
                          item={item}
                          isLargeScreen={isLargeScreen}
                          onClose={onClose}
                          isSelected={isSelectedMenu(item.name)}
                          marginLeft={'0rem'}
                          flagComponent={item.flagComponent}
                        />
                      )}

                      <Transition
                        show={children && isSelectedMenu(item.name)}
                        {...menuItemsTransitionClasses}
                      >
                        {children}
                      </Transition>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        );
      })}
    </nav>
  );
}
