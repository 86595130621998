import * as React from 'react';
import { useMedia } from 'react-use';

export const useSidebar = () => {
  const [open, setOpen] = React.useState<{ below: boolean; above: boolean }>({
    above: true,
    below: false,
  });

  const isWide = useMedia('(min-width: 1280px)', false);

  const isOpen = () => {
    if (isWide) {
      return open.above;
    }
    return open.below;
  };

  const onOpen = () => {
    if (isWide) {
      setOpen((prv) => ({ ...prv, above: true }));
    }
    setOpen((prv) => ({ ...prv, below: true }));
  };

  const onClose = (withClickAway: boolean = false) => {
    if (withClickAway) {
      if (!isWide) {
        setOpen((prv) => ({ ...prv, below: false }));
      }
    } else {
      if (isWide) {
        setOpen((prv) => ({ ...prv, above: false }));
      }
      setOpen((prv) => ({ ...prv, below: false }));
    }
  };

  return {
    open: isOpen(),
    onOpen,
    onClose,
    isWide,
  };
};
