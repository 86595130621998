import type { UploadTask } from 'firebase/storage';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { useRef } from 'react';

import { storage } from '../../config/firebase.config';

export const useDeleteStorageFile = (path: string) => {
  const deleteTask = useRef<any>();

  const deleteFile = () => {
    const storageRef = ref(storage, path);
    deleteTask.current = deleteObject(storageRef);
  };

  return {
    deleteFile,
    deleteTask,
  };
};

export const useUploadFile = (path: string) => {
  const uploadTaskItem = useRef<UploadTask>();

  const storageRef = ref(storage, path);
  const uploadFile = (file?: File | Blob) => {
    if (file) {
      uploadTaskItem.current = uploadBytesResumable(storageRef, file);
    }
  };

  const downloadFileUrl = () => {
    return getDownloadURL(storageRef);
  };

  return {
    uploadTask: uploadTaskItem,
    uploadFile,
    download: downloadFileUrl,
  };
};
