'use client';

import type { FirestoreError } from 'firebase/firestore';
import { createContext } from 'react';
import type { UseQueryResult } from 'react-query';

import type { IUser } from '@/shared/firebase/models/user.interface';

type CurrentUserContextValue = {
  currentUser: IUser | null;
  state?: Omit<UseQueryResult<IUser | undefined, FirestoreError>, 'data'>;
};

export const CurrentUserContext = createContext<CurrentUserContextValue>({
  currentUser: null,
});
