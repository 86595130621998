import { Tooltip } from '@canvaza/serval-ui';
import Link from 'next/link';
import React from 'react';

import { FilesIcon } from '@/shared/utils/svg';

import CanvazaLogo from '../../layout/CanvazaLogo';

export default function Header() {
  return (
    <div className="flex w-full items-center justify-between gap-3 pb-5 ">
      <Tooltip content="Dashboard">
        <CanvazaLogo />
      </Tooltip>
      <Tooltip content="Library">
        <Link href={'/library'}>
          <FilesIcon className="w-6 stroke-primary dark:stroke-primary-light" />
        </Link>
      </Tooltip>
    </div>
  );
}
