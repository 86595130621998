'use client';

import type { FirestoreError } from 'firebase/firestore';
import { createContext } from 'react';
import type { UseQueryResult } from 'react-query';

import type { MemberRole } from '@/shared/config/members-roles.config';
import type { IWorkspaceMember } from '@/shared/firebase/models/workspace-member.interface';

type WorkspaceMemberContextValue = {
  workspaceMember: IWorkspaceMember | null;
  role: MemberRole | null;
  isOwner: boolean;
  state?: Omit<UseQueryResult<IWorkspaceMember[], FirestoreError>, 'data'>;
};

export const WorkspaceMemberContext =
  createContext<WorkspaceMemberContextValue>({
    workspaceMember: null,
    role: null,
    isOwner: false,
  });
