'use client';

import React from 'react';

import { buildAbilityFor } from '@/shared/casl/ability';
import { AbilityContext } from '@/shared/casl/Can';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

type AdminAbilityProviderProps = {
  children: React.ReactNode;
};
export default function AdminAbilityProvider({
  children,
}: AdminAbilityProviderProps) {
  const { currentUser: user } = useCurrentUser();
  const tempPermissions: string[] = [];
  if (user?.roles) {
    user.roles.forEach((role) => {
      if (role.permissions) {
        role.permissions.forEach((permission) => {
          tempPermissions.push(permission);
        });
      }
    });
  }

  if (user?.permissions) {
    tempPermissions.push(...user.permissions);
  }

  const formPermissions = tempPermissions.map((permission) => {
    const actionsSubjects = permission.split('-');
    return { action: actionsSubjects[0]!, subject: actionsSubjects[1]! };
  });

  const ability = buildAbilityFor(formPermissions);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}
