'use client';

import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';

import type { LayoutProps } from '@/app/types';
import { isAdminPath, isSettingPath } from '@/middleware/paths';
import { isFMCTokenExist, setIsFMCTokenExist } from '@/providers/AuthProvider';
import useFCM from '@/shared/firebase/fmc/hooks/useFCM';
import { useAuth } from '@/shared/hooks/useAuth';

import AdminLayout from './AdminLayout';
import SettingLayout from './SettingLayout';
import WorkspaceLayout from './WorkspaceLayout';

export default function AppLayout({ children }: LayoutProps) {
  const pathname = usePathname();
  const { addFMCToken } = useFCM();
  const { user: authUser } = useAuth();

  useEffect(() => {
    if (!isFMCTokenExist() && authUser) {
      addFMCToken().then(() => setIsFMCTokenExist(true));
    }
  }, [authUser]);

  if (isSettingPath(pathname)) {
    return <SettingLayout>{children}</SettingLayout>;
  }

  if (isAdminPath(pathname)) {
    return <AdminLayout>{children}</AdminLayout>;
  }

  return <WorkspaceLayout>{children}</WorkspaceLayout>;
}
