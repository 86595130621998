'use client';

import { useEffect, useState } from 'react';

import { Endpoints } from '@/shared/config/endpoint.config';
import { usePostAPI } from '@/shared/hooks/fetch.hooks';
import { useAuth } from '@/shared/hooks/useAuth';

import { generateFMCToken } from '../../config/firebase.config';
import type { NotificationKeySet } from '../../models';
import useNotificationPermission from './useNotificationPermission';

const useFCMToken = () => {
  const permission = useNotificationPermission();
  const [fcmToken, setFcmToken] = useState<string | null>(null);

  const { user: authUser } = useAuth();
  const { post: addToken } = usePostAPI<any, NotificationKeySet>();
  const { post: removeToken } = usePostAPI<any, NotificationKeySet>();

  const addFMCToken = async () => {
    if (fcmToken) {
      await addToken({
        apiUrl: Endpoints.notification.appEngines.addFMCToken(),
        body: {
          registration_id: fcmToken,
        },
      });
    }
  };

  const removeFMCToken = async () => {
    if (authUser && fcmToken)
      removeToken({
        apiUrl: Endpoints.notification.appEngines.removeFMCToken(authUser.uid),
        body: {
          registration_id: fcmToken,
        },
      });
  };

  useEffect(() => {
    const retrieveToken = async () => {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        if (permission === 'granted') {
          generateFMCToken(permission).then((currentToken) =>
            setFcmToken(currentToken)
          );
        }
      }
    };
    retrieveToken();
  }, [permission]);

  return { fcmToken, addFMCToken, removeFMCToken };
};

export default useFCMToken;
