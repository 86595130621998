import type { AbilityClass } from '@casl/ability';
import { Ability, AbilityBuilder } from '@casl/ability';

import type { Actions, RawRuleTypes, Subjects } from './types';

export type AppAbilityType = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

export default function defineRulesFor(permissions: RawRuleTypes[]) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (permissions.length > 0) {
    permissions.forEach((permission) => {
      can(permission.action as Actions, permission.subject as Subjects);
    });
  }

  return rules;
}

export function buildAbilityFor(permissions: RawRuleTypes[]): AppAbilityType {
  return new AppAbility(defineRulesFor(permissions), {
    // detectSubjectType: (object) => object!.type,
  });
}
