import type { AbilityClass } from '@casl/ability';
import { Ability, AbilityBuilder } from '@casl/ability';

import type {
  IUserActions,
  IUserSubjects,
} from '../config/members-roles.config';
import type { RawRuleTypes } from './types';

export type AppAbilityType = Ability<[IUserActions, IUserSubjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

export default function defineRulesFor(permissions: RawRuleTypes[]) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (permissions.length > 0) {
    permissions.forEach((permission) => {
      can(
        permission.action as IUserActions,
        permission.subject as IUserSubjects
      );
    });
  }

  return rules;
}

export function buildAbilityFor(permissions: RawRuleTypes[]): AppAbilityType {
  return new AppAbility(defineRulesFor(permissions), {});
}
