export function getVideoCover(file: File, seekTo = 0.0) {
  return new Promise((resolve, _reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');

    videoPlayer.setAttribute('src', window.URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', (_ex) => {});
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx?.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.75 /* quality */
        );
      });
    });
  });
}

export function getVideoDuration(
  file: File
): Promise<number | ProgressEvent<FileReader>> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result as string);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });
}

export const formatVideoDuration = (seconds: number): string => {
  if (seconds >= 3600)
    return new Date(seconds * 1000).toISOString().slice(11, 19);
  return new Date(seconds * 1000).toISOString().substring(14, 19);
};

export const videoFileTypes = ['mp4', 'webm'] as const;
export type VideoFileType = (typeof videoFileTypes)[number];
